import React, { Component } from 'react';
import './App.css';   
import { Route, Switch } from "react-router-dom";
import { connect } from "react-redux";
import ProtectedRoute from "./components/ProtectedRoute";
import CoverageMap from './view/CoverageMap';
import Login from './components/Login'
import ManageUsers from './view/ManageUsers';
import ManageClients from './view/ManageClients';
 

function App(props) {
  const { isAuthenticated, isVerifying, isAuthorized } = props;
  return (
    <Switch>
      <ProtectedRoute
        exact
        path="/"
        component={CoverageMap}
        isAuthenticated={isAuthenticated}
        isVerifying={isVerifying}
        isAuthorized={isAuthorized}
      />
      <ProtectedRoute
        exact
        path="/manage/users"
        component={ManageUsers}
        isAuthenticated={isAuthenticated}
        isVerifying={isVerifying}
        isAuthorized={isAuthorized}
      />
      <ProtectedRoute
        exact
        path="/manage/clients"
        component={ManageClients}
        isAuthenticated={isAuthenticated}
        isVerifying={isVerifying}
        isAuthorized={isAuthorized}
      />
      <Route path="/login" component={Login} />       
    </Switch>
  );
}

function mapStateToProps(state) {
  return {
    isAuthenticated: state.auth.isAuthenticated,
    isVerifying: state.auth.isVerifying,
    isAuthorized: state.auth.isAuthorized
  };
}

export default connect(mapStateToProps)(App);