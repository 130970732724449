import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";

const firebaseConfig = {
    apiKey: "AIzaSyARq7sUnKoQKK-jwBLB_ncj3rIOEpJRSpY",
    authDomain: "coverage-hosting.firebaseapp.com",
    databaseURL: "https://coverage-hosting.firebaseio.com",
    projectId: "coverage-hosting",
    storageBucket: "coverage-hosting.appspot.com",
    messagingSenderId: "174293551375",
    appId: "1:174293551375:web:876a98a74677e518fa967a"
};

export const appFirebase = firebase.initializeApp(firebaseConfig);
export const tmpFirebase = firebase.initializeApp(firebaseConfig, "Secondary");

export const googleProvider = new firebase.auth.GoogleAuthProvider();

const baseDb = appFirebase.firestore();
baseDb.settings({
    // timestampsInSnapshots: true
})
export const db = baseDb;