import { appFirebase, googleProvider, db, tmpFirebase } from "../services/Firebase";
import utils from "../Utils/utils";
import { logAction } from "./logger";

export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";

export const AUTHORIZING_REQUEST = "AUTHORIZING_REQUEST";
export const AUTHORIZING_SUCCESS = "AUTHORIZING_SUCCESS";
export const AUTHORIZING_FAILURE = "AUTHORIZING_FAILURE";

export const LOGOUT_REQUEST = "LOGOUT_REQUEST";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOGOUT_FAILURE = "LOGOUT_FAILURE";

export const RESET_PASSWORD_REQUEST = "RESET_PASSWORD_REQUEST";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_FAILURE = "RESET_PASSWORD_FAILURE";

export const VERIFY_REQUEST = "VERIFY_REQUEST";
export const VERIFY_SUCCESS = "VERIFY_SUCCESS";

export const SIGNUP_REQUEST = "SIGNUP_REQUEST";
export const SIGNUP_FAILURE = "SIGNUP_FAILURE";

export const ROLES = ['admin', 'viewer']

const requestLogin = () => {
    return {
        type: LOGIN_REQUEST
    };
};
const receiveLogin = user => {
    return {
        type: LOGIN_SUCCESS,
        user
    };
};
const loginError = (error) => {
    return {
        type: LOGIN_FAILURE,
        error
    };
};

const requestAuthorizing = () => {
    return {
        type: AUTHORIZING_REQUEST
    };
};
const receiveAuthorizing = auth => {
    let isAuthorized = auth ? true : false
    return {
        type: AUTHORIZING_SUCCESS,
        isAuthorized: isAuthorized,
        auth
    };
};
const authorizingError = (error) => {
    return {
        type: AUTHORIZING_FAILURE,
        isAuthorized: false,
        error
    };
};

const requestLogout = () => {
    return {
        type: LOGOUT_REQUEST
    };
};

const receiveLogout = () => {
    return {
        type: LOGOUT_SUCCESS
    };
};

const logoutError = (error) => {
    return {
        type: LOGOUT_FAILURE,
        error
    };
};

const verifyRequest = () => {
    return {
        type: VERIFY_REQUEST
    };
};
const verifySuccess = user => {
    return {
        type: VERIFY_SUCCESS
    };
};

const requestSignup = () => {
    return {
        type: SIGNUP_REQUEST
    };
};
const signupError = (error) => {
    return {
        type: SIGNUP_FAILURE,
        error
    };
};


const requestResetPassword = () => {
    return {
        type: RESET_PASSWORD_REQUEST
    };
};
const receiveResetPassword = auth => {
    let isAuthorized = auth ? true : false
    return {
        type: RESET_PASSWORD_SUCCESS,
        isAuthorized: isAuthorized,
        auth
    };
};
const resetPasswordError = (error) => {
    return {
        type: RESET_PASSWORD_FAILURE,
        error
    };
};

export const loginUser = (email, password) => dispatch => {
    dispatch(requestLogin());
    let userDetails = {}
    appFirebase
        .auth()
        .signInWithEmailAndPassword(email, password)
        .then(user => {
            dispatch(requestAuthorizing());
            authoriseUser(email).then(details => {
                userDetails.details = details
                user.details = details;
                if (!details || !details.role || !ROLES.includes(details.role)) {
                    throw ("Unauthorised")
                }
                dispatch(receiveAuthorizing(details));
                dispatch(receiveLogin(user));
            }).catch(err => {
                console.error(err)
                dispatch(authorizingError(err));
                dispatch(loginError(err));
            })
        })
        .catch(error => {
            console.error(error)
            dispatch(loginError(error));
             // If there is an error in the catch block, handle it here
            if (userDetails && userDetails.details && userDetails.details.email) {
                // Handle the case where 'user' is defined
                logAction(userDetails.details, 'usermanagement.login', 'Failed to login')
            } else {
                // Handle the case where 'user' is not defined
                console.error('User not defined. Failed to login user.');
            }
        });
};

export const signUpUser = (email, password) => dispatch => {
    if (!password) password = utils.getRandom(8);
    dispatch(requestSignup());
    let userDetails = {}
    tmpFirebase
        .auth()
        .createUserWithEmailAndPassword(email, password)
        .then((user) => {
            tmpFirebase
                .auth()
                .sendPasswordResetEmail(email)
                .then(function () {
                    userDetails = user
                    const info = {
                        data: {...user.details}
                        }
                    if(user?.details?.email) logAction(user.details, 'usermanagement.add', 'New user successfully added' , info)
                    tmpFirebase.auth().signOut();
                })
        })
        .catch(error => {
            if (error.code === "auth/email-already-in-use") {
                tmpFirebase
                    .auth()
                    .sendPasswordResetEmail(email)
                    .then(function () {
                        tmpFirebase.auth().signOut();
                    }).catch(function (error) {

                    });
            } else {
                console.log('create user email and password error')
                console.log(error)
                dispatch(signupError(error));
            }
        });
};

export const deleteFirebaseUser = (user) => dispatch => {
    user.delete().then(function () {
    const info = {
        data: {...user.details}
        }
        logAction(user.details, 'usermanagement.delete', 'User successfully deleted', info)
    }).catch(function (error) {
        console.log('delete user error')
        console.log(error)
    });
}

export const sendUserPasswordReset = (email) => dispatch => {
    dispatch(requestResetPassword())
    appFirebase
        .auth()
        .sendPasswordResetEmail(email)
        .then(function () {
            dispatch(receiveResetPassword())
        }).catch(function (error) {
            console.log('password reset error')
            console.log(error)
            dispatch(resetPasswordError(error))
        });
}

function authoriseUser(id) {
    return new Promise((resolve, reject) => {
        let ref = db.collection('users').doc(id)
        let doc = ref.get()
            .then(doc => {
                console.log('auth received')
                if (!doc.exists || !doc.data().active) {
                    let err = { code: 403, message: "Unauthorised" }
                    reject(err)
                    return;
                }
                let auth = doc.data();
                console.log(' atuh success Dispatch')
                resolve(auth)
            })
            .catch(error => {
                console.log(' auth error Dispatch')
                reject(error)
            });
    });
}

export const loginUserWithGoogle = () => dispatch => {
    googleProvider.addScope('profile');
    googleProvider.addScope('email');
    googleProvider.addScope('https://www.googleapis.com/auth/contacts.readonly');
    dispatch(requestLogin());
    appFirebase
        .auth()
        // .signInWithRedirect(googleProvider)
        .signInWithPopup(googleProvider)
        .then(result => {
            let usernew = result.additionalUserInfo.isNewUser;
            if (usernew) throw ('User not authorised to use this application')
            //dispatch(addUser(result.user));
            return result.user;
        })
        .then(user => {
            dispatch(requestAuthorizing());
            authoriseUser(user.email).then(details => {
                user.details = details;
                if (!details || !details.role || !ROLES.includes(details.role)) {
                    throw ("Unauthorised")
                }
                dispatch(receiveAuthorizing(details));
                dispatch(receiveLogin(user));
            }).catch(err => {
                console.error(err)
                dispatch(authorizingError(err));
                dispatch(loginError(err));
            })
        })
        .catch(error => {
            dispatch(loginError(error));
            
        });
};

export const logoutUser = () => dispatch => {
    dispatch(requestLogout());
    appFirebase
        .auth()
        .signOut()
        .then(() => {
            dispatch(receiveLogout());
        })
        .catch(error => {
            dispatch(logoutError(error));
        });
};

export const verifyAuth = () => dispatch => {
    dispatch(verifyRequest());
    appFirebase.auth().onAuthStateChanged(user => {
        dispatch(verifySuccess());
        if (user !== null) {
            let userDetails
            dispatch(requestAuthorizing());
            authoriseUser(user.email).then(details => {
                userDetails = details
                user.details = details;
                if (!details || !details.role || !ROLES.includes(details.role) || !details.active) {
                    throw ("Unauthorised")
                }
                dispatch(receiveAuthorizing(details));
                dispatch(receiveLogin(user));
                 const info = {
                    info : 'User login in',
                    data: {...user.details}

                }
                if(user?.details?.email) logAction(user.details, 'usermanagement.login', 'User successfully logged in', info)
            }).catch(err => {
                if(userDetails) {
                    logAction(user.details, 'usermanagement.login', 'Failed to login')
                } else console.log('Failed to login user')
                dispatch(authorizingError());
                dispatch(loginError(err));
            })
        }
    });
};