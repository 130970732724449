import React, {Component} from 'react';
import styles from './styles.module.css'
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import {makeStyles} from '@material-ui/core/styles';
import parse from 'autosuggest-highlight/parse';
import throttle from 'lodash/throttle';
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import SearchIcon from "@material-ui/icons/Search";
import ClearIcon from "@material-ui/icons/Clear";
import Divider from "@material-ui/core/Divider";
import FilterListIcon from "@material-ui/icons/FilterList";
import AdminActions from "../AdminActions";
import {Container} from "@material-ui/core";

class GoogleAutocomplete extends Component {
  googleGeocoderRef = '';

    constructor(props) {
        super(props)
        this.state = {
            addressValue: '',
            displayFilter: true,
            addressClearable: false,
            autocompleteService: {current: null},
            inputValue: '',
            options: [],
            loaded: {current: false},
            autocompleteOptions: {
                componentRestrictions: {
                    country: 'ZA'
                },
                fields: ["address_components", "formatted_address", "geometry"]
            },
            sessionToken: ''
        }
    }

    handleChange = (e) => {
        let self = this;

        this.setState({inputValue: e.target.value, addressClearable: true}, (state) => {
            self.getPredictions();
        });
    }

    getPredictions() {
        let self = this;
        let val = this.state.autocompleteOptions;
        val.input = this.state.inputValue;
        let sessionToken = this.getUUID();

        if (!this.state.autocompleteService.current && window.google) {
            let service = this.state.autocompleteService;
            service.current = new window.google.maps.places.AutocompleteService();
            this.setState({autocompleteService: service});
        }

        if (!this.state.autocompleteService.current) {
            return undefined;
        }

        this.state.autocompleteService.current.getPlacePredictions({
            input: this.state.inputValue,
            sessionToken: sessionToken,
            componentRestrictions: {country: 'ZA'}
        }, (predictions) => {
            let active = true;
            if (!predictions) active = false

            if (self.state.inputValue === '') {
                self.setState({options: []});
                return undefined;
            }

            if (active) {
                self.setState({options: predictions || ['none']});
            }
        });
    }

    getUUID = () => {
        let sessionToken = this.state.sessionToken;
        if (!sessionToken) {
            sessionToken = new window.google.maps.places.AutocompleteSessionToken();
            this.setState({sessionToken: sessionToken})
        }
        return sessionToken;
    }

    handleAddressChange = (address) => {
        let processedAddress = '' 
        this.setState({addressValue: address})
        let sessionToken = this.state.sessionToken;
        this.setState({sessionToken: ''})
        if (address.description) {
            this.getPlaceDetails(address, sessionToken)
        } else {
            const coords = this.validateCoords(address)
        if (coords) {
            this.reverseGeocoding(+coords[0], +coords[1])
            }
        }
    }

    reverseGeocoding = (latitude, longitude) => {
        if (!window.google) throw 'Google Maps not initialised'
        if (!this.googleGeocoderRef || !this.googleGeocoderRef) this.googleGeocoderRef = new window.google.maps.Geocoder()
        this.googleGeocoderRef.geocode({ location: { lat: latitude, lng: longitude } }, (results, status) => {
            if (status === 'OK') {
            if (results[0]) {
                // take the first result
                results[0].geometry.location = {
                lat: () => {
                    return latitude
                },
                lng: () => {
                    return longitude
                },
                }
                this.setState({ addressValue: results[0] })
                this.props.handleAddressChange(results[0])
            } else {
                window.alert('No results found')
            }
            } else {
            console.error('Geocoder failed due to: ' + status)
            }
        })
    }

    validateCoords = (value) => {
        if (!value) return false
        let splitVal = value.split(',')
        if (!splitVal || splitVal.length < 2) splitVal = value.split(' ')
        if (!splitVal || splitVal.length < 2 || !splitVal[0] || !splitVal[1]) return false
        if (splitVal[0] > -90 && splitVal[0] < 90 && splitVal[1] > -180 && splitVal[1] < 180) return splitVal
        return false
    }

    getPlaceDetails = (address, sessionToken) => {
        let service = new window.google.maps.places.PlacesService(document.createElement('div'));
        try {
            service.getDetails({
                placeId: address.place_id,
                sessiontoken: sessionToken.sh,
                fields: ['address_component', 'formatted_address', 'geometry']
            }, (place, status) => {
                if (this.props.handleAddressChange) this.props.handleAddressChange(place);
            });
        } catch (err) {
            console.log(err)
        }
    }

    clearAddressField = (event) => {
        let addressClearable = this.state.addressClearable
        addressClearable = false;
        this.setState({addressClearable: addressClearable, addressValue: ''});
        if (this.props.handleAddressChange) this.props.handleAddressChange('');
    }

    handleProfileMenuOpen = (event) => {
        this.setState({isMenuOpen: !this.state.isMenuOpen, anchorEl: event.currentTarget})
    }

    handleMenuClose = (event) => {
        this.setState({isMenuOpen: false})
    }

    handleFilterToggle = (event) => {
        if(this.props.handleFilterToggle) this.props.handleFilterToggle();
    }

    render() {
        return (
            <div className={styles.root}>
                <Autocomplete
                    id="google-map-field"
                    value={this.state.addressValue}
                    getOptionLabel={
                      (option) => { 
                            if(option?.description){
                                return option.description
                            } else if(option?.formatted_address){
                                return option.formatted_address
                            } else if (typeof option === 'string') {
                                return option
                            }
                        }
                    }
                    filterOptions={x => x}
                    // className={styles.search}
                    styles={"padding: 0px"}
                    options={this.state.options}
                    autoComplete
                    includeInputInList
                    freeSolo
                    disableOpenOnFocus
                    classes={{
                        option: styles.option,
                    }}
                    onChange={(event, newValue) => {
                        this.handleAddressChange(newValue);
                    }}
                    renderInput={params => (
                        <TextField
                            {...params}
                            // label="Address"
                            fullWidth
                            onChange={this.handleChange}
                            margin="dense"
                            className={styles.inputBase}
                            placeholder="Enter an address or XY (latitude, longitude)"
                            InputProps={{
                                ...params.InputProps,
                                className: styles.autocomplete,
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <AdminActions
                                            user={this.props.user}
                                            className={styles.dark}
                                        />
                                    </InputAdornment>
                                ),
                                endAdornment: (
                                    <InputAdornment > {
                                        !this.state.addressClearable ?
                                            <IconButton >
                                                <SearchIcon />
                                            </IconButton>
                                            : <IconButton onClick={this.clearAddressField} >
                                                <ClearIcon />
                                            </IconButton>
                                    }
                                        {/* <Divider orientation="vertical" className={styles.devider} />
                                        <IconButton onClick={this.handleFilterToggle} >
                                            <FilterListIcon />
                                        </IconButton> */}
                                    </InputAdornment>
                                )

                            }}
                        />
                    )}
                    renderOption={option => {
                        const matches = option.structured_formatting.main_text_matched_substrings;
                        const parts = parse(
                            option.structured_formatting.main_text,
                            matches.map(match => [match.offset, match.offset + match.length]),
                        );
                        return (
                            <Grid container alignItems="center">
                                <Grid item>
                                    <LocationOnIcon/>
                                </Grid>
                                <Grid item xs>
                                    {parts.map((part, index) => (
                                        <span key={index} style={{fontWeight: part.highlight ? 700 : 400}}>
                                        {part.text}
                                    </span>
                                    ))}

                                    <Typography variant="body2" color="textSecondary">
                                        {option.structured_formatting.secondary_text}
                                    </Typography>
                                </Grid>
                            </Grid>
                        );
                    }}
                />
            </div>
        );
    }
}

export default GoogleAutocomplete;
