import { appFirebase, db } from "../services/Firebase";
import { logAction } from "./logger";

export const ADD_CLIENT_REQUEST = "ADD_CLIENT_REQUEST";
export const ADD_CLIENT_SUCCESS = "ADD_CLIENT_SUCCESS";
export const ADD_CLIENT_FAILURE = "ADD_CLIENT_FAILURE";

export const GET_CLIENT_REQUEST = "GET_CLIENT_REQUEST";
export const GET_CLIENT_SUCCESS = "GET_CLIENT_SUCCESS";
export const GET_CLIENT_FAILURE = "GET_CLIENT_FAILURE";

export const UPDATE_CLIENT_REQUEST = "UPDATE_CLIENT_REQUEST";
export const UPDATE_CLIENT_SUCCESS = "UPDATE_CLIENT_SUCCESS";
export const UPDATE_CLIENT_FAILURE = "UPDATE_CLIENT_FAILURE";

export const DELETE_CLIENT_REQUEST = "DELETE_CLIENT_REQUEST";
export const DELETE_CLIENT_SUCCESS = "DELETE_CLIENT_SUCCESS";
export const DELETE_CLIENT_FAILURE = "DELETE_CLIENT_FAILURE";

const requestAddClient = () => {
    return {
        type: ADD_CLIENT_REQUEST
    };
};
const recieveAddClient = obj => {
    return {
        type: ADD_CLIENT_SUCCESS,
        obj
    };
};
const AddClientError = (error) => {
    return {
        type: ADD_CLIENT_FAILURE,
        error
    };
};

const requestGetClient = () => {
    return {
        type: GET_CLIENT_REQUEST
    };
};
const recieveGetClient = clients => {
    return {
        type: GET_CLIENT_SUCCESS,
        clients
    };
};
const GetClientError = (error) => {
    return {
        type: GET_CLIENT_FAILURE,
        error
    };
};

const requestUpdateClient = () => {
    return {
        type: UPDATE_CLIENT_REQUEST
    };
};
const recieveUpdateClient = obj => {
    return {
        type: UPDATE_CLIENT_SUCCESS,
        obj
    };
};
const updateClientError = (error) => {
    return {
        type: UPDATE_CLIENT_FAILURE,
        error
    };
};

const requestDeleteClient = () => {
    return {
        type: DELETE_CLIENT_REQUEST
    };
};
const recieveDeleteClient = obj => {
    return {
        type: DELETE_CLIENT_SUCCESS,
        obj
    };
};
const deleteClientError  = (error) => {
    return {
        type: DELETE_CLIENT_FAILURE,
        error
    };
}; 

export const addClient = (data, user) => dispatch => {
    dispatch(requestAddClient());
    try {
        if (!data.name) {
            throw ({ error: "name not specified" });
        }
        let ref = db.collection("clients").doc(data.name);
        let doc = ref.set(data)
        const added = {
            data: data
        }
        dispatch(recieveAddClient(doc))
        logAction(user.details, 'clientmanagement.add', 'New client successfully added' , added)
    } catch (error) {
        logAction(user.details, 'clientmanagement.add', 'Failed to create new client')
        dispatch(AddClientError(error));
    };
};

export const deleteClient = (data, user) => dispatch => {
    dispatch(requestDeleteClient());
    try {
        if (!data.name) {
            throw ({ error: "name not specified" });
        }  
        
        let ref = db.collection("clients").doc(data.name);
        let doc = ref.delete();
        const deleted = {
            data: data
        }
        dispatch(recieveDeleteClient(doc))
        logAction(user.details, 'clientmanagement.delete', 'Client successfully deleted' , deleted)

    } catch (error) {
        logAction(user.details, 'clientmanagement.delete', 'Failed to delete client')
        dispatch(deleteClientError(error));
    };
};

export const updateClient = (newData, oldData, user) => dispatch => {
    dispatch(requestUpdateClient());
    try {
        if (!newData.name) {
            throw ({ error: "name not specified" });
        }   
        let ref = db.collection("clients").doc(oldData.name);
        let doc = ref.update(newData)
         const update = {
            data: {
                from: oldData,
                to: newData
            }
        }
        dispatch(recieveUpdateClient(doc))
        logAction(user.details, 'clientmanagement.update', `Successfully updated client` , update)
    } catch (error) {
        logAction(user.details, 'clientmanagement.update', 'Failed to update client')
        dispatch(updateClientError(error));
    };
}; 

export const GetClient = (collectionName, id) => dispatch => {
    dispatch(requestGetClient());
    try {
        let ref = db.collection(collectionName).doc(id)
        let doc = ref.get()
            .then(doc => {
                if (!doc.exists) dispatch(recieveGetClient({}));
                else dispatch(recieveGetClient(doc.data()));
            })
            .catch(error => {
                dispatch(GetClientError(error));
            });
    } catch (error) {
        dispatch(GetClientError(error));
    };
};
   
export const getClients = () => dispatch => {
    dispatch(requestGetClient()); 
    try {
        let ref = db.collection('clients')
        let doc = ref.get()
            .then(snapshot => {
                let clients = []
                snapshot.forEach(doc => {
                    clients.push(doc.data())
                });
                dispatch(recieveGetClient(clients))
            })
            .catch(err => {
                dispatch(GetClientError(err));
            });
    } catch (error) {
        dispatch(GetClientError(error));
    };
};
