import {
    LOGIN_REQUEST,
    LOGIN_SUCCESS,
    LOGIN_FAILURE,
    AUTHORIZING_REQUEST,
    AUTHORIZING_SUCCESS,
    AUTHORIZING_FAILURE,
    LOGOUT_REQUEST,
    LOGOUT_SUCCESS,
    LOGOUT_FAILURE,
    VERIFY_REQUEST,
    VERIFY_SUCCESS,
    SIGNUP_FAILURE,
    SIGNUP_REQUEST,
    RESET_PASSWORD_FAILURE,
    RESET_PASSWORD_REQUEST,
    RESET_PASSWORD_SUCCESS
} from "../actions";


export default (
    state = {
        isLoggingIn: false,
        isLoggingOut: false,
        isVerifying: false,
        loginError: false,
        logoutError: false,
        isAuthenticated: false,
        isAuthorizing: false,
        isAuthorized: false,
        authError: false,
        signUpError: false,
        user: {}
    },
    action
) => {
    switch (action.type) {
        case LOGIN_REQUEST:
            return {
                ...state,
                isLoggingIn: true,
                loginError: false
            };
        case LOGIN_SUCCESS:
            return {
                ...state,
                isLoggingIn: false,
                isAuthenticated: true,
                loginError: false,
                user: action.user
            };
        case LOGIN_FAILURE:
            return {
                ...state,
                isLoggingIn: false,
                isAuthenticated: false,
                loginError: true
            };
        case AUTHORIZING_REQUEST: 
            return {
                ...state, 
                isAuthorizing: true,
                isAuthorized: false
            };
        case AUTHORIZING_SUCCESS: 
            return {
                ...state,
                isAuthorizing: true,
                isAuthorized: action.isAuthorized,
                auth: action.auth
            };
        case AUTHORIZING_FAILURE: 
            return {
                ...state, 
                isAuthorizing: false,
                isAuthorized: false,
                authError: action.error
            };
        case LOGOUT_REQUEST:
            return {
                ...state,
                isLoggingOut: true,
                logoutError: false
            };
        case LOGOUT_SUCCESS:
            return {
                ...state,
                isLoggingOut: false,
                isAuthenticated: false,
                user: {}
            };
        case LOGOUT_FAILURE:
            return {
                ...state,
                isLoggingOut: false,
                logoutError: true
            };
        case VERIFY_REQUEST:
            return {
                ...state,
                isVerifying: true,
                verifyingError: false
            };
        case VERIFY_SUCCESS:
            return {
                ...state,
                isVerifying: false
            };
        case SIGNUP_REQUEST:
            return {
                ...state,
                isLoggingIn: true,
                loginError: false
            }; 
        case SIGNUP_FAILURE:
            return {
                ...state,
                isLoggingIn: false,
                isAuthenticated: false,
                loginError: true
            };
        case RESET_PASSWORD_SUCCESS:
            return {
                ...state,
            };
        case RESET_PASSWORD_FAILURE:
            return {
                ...state, 
            };
        case RESET_PASSWORD_REQUEST: 
            return {
                ...state,  
            };
        default:
            return state;
    }
};