import React, { Component } from 'react';
import GoogleMaps from '../GoogleMaps';
import { connect } from "react-redux";
import DeckGL from '@deck.gl/react';
import { GeoJsonLayer, ScatterplotLayer } from '@deck.gl/layers';
import { GoogleMapsOverlay } from '@deck.gl/google-maps';
import styles from './styles.module.css'
import {TileLayer} from '@deck.gl/geo-layers';

const COLOR_SCALE = {
    gain: [3, 241, 73],
    loss: [245, 0, 17],
    status: [255, 235, 59 ]
};

class DeckGLMapComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedMapStyle: 'grey',
            map: {},
            dataLayers: [],
            infowindow: null,
            fitler: {
                service: props.filter.service,
                provider: props.filter.provider,
                status: props.filter.status
            },
            mapOptions: {
                center: { lat: -26, lng: 25 },
                zoom: 6,
                minZoom: 3,
                maxZoom: 20,
                scaleControl: false,
                clickableIcons: false,
                mapTypeControl: true,
                zoomControl: true,
                streetViewControl: false,
                styles: props.mapStyle,
            },
            mapFilter: [],
            apikey: process.env.REACT_APP_GAPI,
            marker: null,
            mapLoading: false,
            displayMapLoad: false,
            currentHover: null,
            displayLayers: [],
        }
        this.googleGeocoder = null
    }

    handleMapLoad = (map) => {
        this.setState({ map: map }, (state) => {
            this.handleDataLayers();
            if (this.props.handleMapLoad) this.props.handleMapLoad();
            this.addMapClickListener();

            let options = this.state.mapOptions;
            options.fullscreenControl = false;
            options.mapTypeControlOptions = {
                position: window.google.maps.ControlPosition.RIGHT_BOTTOM,
                style: window.google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
                mapTypeIds: [
                    window.google.maps.MapTypeId.ROADMAP,
                    window.google.maps.MapTypeId.HYBRID,
                    window.google.maps.MapTypeId.TERRAIN,
                    window.google.maps.MapTypeId.SATELLITE
                ]
            }
            options.zoomControlOptions = {
                position: window.google.maps.ControlPosition.RIGHT_CENTER
            }

            this.setState({ mapOptions: options })
            this.state.map.setOptions(options)
            this.initialiseGoogleAutocomplete()
        });
    }

    initialiseGoogleAutocomplete = (input) => {
        if (this.state.map && input) {
            let self = this;
            // let input = document.getElementById('pac-input');
            let options = {
                componentRestrictions: {
                    country: 'ZA'
                },
                fields: ["address_components", "formatted_address", "geometry"]
            };

            let autocomplete = new window.google.maps.places.Autocomplete(input, options);
            autocomplete.bindTo('bounds', this.state.map);
            autocomplete.addListener('place_changed', function () {
                let place = autocomplete.getPlace();
                if (self.onPlaceSearch){
                   self.onPlaceSearch(place)
                }
                if (place && place.geometry) {
                    let pos = new window.google.maps.LatLng(place.geometry.location.lat(), place.geometry.location.lng());
                    self.goToPosition(pos);
                    self.updateMarker(pos);
                    if (self.props.onPlaceSearch) self.props.onPlaceSearch(autocomplete.getPlace());
                }
            });
        }
    }

    handleAddress = (place) => {
        if (place && place.geometry) {
            let pos = new window.google.maps.LatLng(place.geometry.location.lat(), place.geometry.location.lng());
            this.goToPosition(pos);
            this.updateMarker(pos);
            if (this.props.onPlaceSearch) this.props.onPlaceSearch(place);
        }
    }

    goToPosition = (pos) => {
        this.state.map.setZoom(16);
        this.state.map.panTo(pos);
    }

    updateMapStyle = (mapStyle) => {
        let options = this.state.mapOptions;
        options.styles = mapStyle;//this.props.mapStyle;
        this.setState({ mapOptions: options })
        this.state.map.setOptions(this.state.mapOptions)
    }

    handleLayer = (layer) => {
        // console.log(this.state.map);
    }

    handleDataLayers = (layer) => {
        if (this.state.map && layer) {
            let datalayers = this.state.dataLayers;
            if (layer.display && layer.checked && datalayers[layer.name] && datalayers[layer.name].layer && typeof datalayers[layer.name].layer.setMap === 'function') {
                datalayers[layer.name].layer.setMap(this.state.map);
            } else if (layer && layer.display && layer.checked) {
                datalayers[layer.name] = { layer: {}, deckGlLayer: {}, gmpOverlay: null }
                datalayers[layer.name].layer = layer;
                let mapFilters = this.state.mapFilter;
                this.filterDataLayers(mapFilters[layer.name] ? mapFilters[layer.name] : {}, layer.name);

            }

            this.setState({ dataLayers: datalayers }, (state) => {
                this.manageFilter();
            });

        }
    }

    setTooltip = (info) => {
        let tooltip = document.getElementById('tooltip');
        if (info && info !== 'none') {
            tooltip.style.display = 'block';
            tooltip.style.left = (info.x - (tooltip.style.width / 2)) + 'px';
            tooltip.style.top = (info.y + 15) + 'px';
            tooltip.innerHTML = this.handleFeatureInfowindow(info.object);
        } else if(info === 'none'){
            tooltip.style.display = 'none';
        }
    }

    createDeckGlLayer = (layer) => {
        let self = this;
        return new GeoJsonLayer({
            id: layer.name,
            data: layer.data,
            // Styles
            filled: true,
            stroked: false,
            pointRadiusMinPixels: 10,
            pointRadiusScale: 20,
            getFillColor: f => this.colorScale(f.properties),
            // Interactive props
            pickable: true,
            autoHighlight: true,
            dataComparator: oldData => layer.data,
            // _dataDiff: (oldData, newData) => [{startRow: index, endRow: index + 1}],
            onClick: info => {
                // self.updateInfoWindow(new window.google.maps.LatLng(info.lngLat[1], info.lngLat[0]), self.handleFeatureInfowindow(info.object));
            },
            onHover: info => {
                // window.document.pointer
                // self.updateInfoWindow(new window.google.maps.LatLng(info.lngLat[1], info.lngLat[0]), self.handleFeatureInfowindow(info.object));
                self.setTooltip(info)
            },
        })
    }

    colorScale = (proporties) => {
        let visible = this.handleFilter(proporties)
        let type = proporties.type ? proporties.type.toLowerCase() : ''
        return visible ? COLOR_SCALE[type] : [0, 0, 0, 0]
    }

    manageFilter = () => {
        if (this.state.mapFilter && this.state.mapFilter.length > 0) {
            this.state.mapFilter.forEach((value, index, selfV) => {
                this.filterDataLayers(value, selfV);
            })
        }
    }

    handleFilter = (feature, filter = null) => {
        if (!filter) filter = this.state.filter
        let visible = true
        if (filter) {
            if (visible && filter.service) visible = feature.properties['service'] === filter.service;
            if (visible && filter.provider) {
                visible = filter.provider.includes(feature.properties['provider']);
            }
            if (visible && filter.status) visible = feature.properties['status'] === filter.status;
        }
        return visible
    }

    filterDataLayers = (filter, layerName) => {
        if (!layerName) { return };
        this.removeInfoWindow()
        let mapFilters = this.state.mapFilter;
        mapFilters[layerName] = filter;
        this.setState({ manageFilter: mapFilters })

        let self = this;
        let datalayers = this.state.dataLayers;
        let dataLayer = datalayers[layerName];
        if (dataLayer && dataLayer.layer && dataLayer.layer.display && dataLayer.layer.checked) {
            let data = dataLayer.layer.data
            let filteredData = []
            let tempFeatures = JSON.parse(JSON.stringify(dataLayer.layer));

            data.features.forEach(function (feature) {
                let visible = self.handleFilter(feature, filter);
                if (visible) {
                    filteredData.push(feature)
                }
            });
            let deckGlLayer;
            // if(tempFeatures.data){
                tempFeatures.data.features = filteredData;
                tempFeatures.totalFeatures = filteredData.length;
                deckGlLayer = this.createDeckGlLayer(tempFeatures);
            // }
            let gmpOverlay = dataLayer.gmpOverlay;

            if (gmpOverlay) {
                gmpOverlay.setMap(null)
                gmpOverlay.setProps({ layers: [] })
                gmpOverlay.finalize();
                gmpOverlay = null;
            }
            // if (deckGlLayer){
            gmpOverlay = new GoogleMapsOverlay({
                layers: [deckGlLayer]
            })
            gmpOverlay.setMap(this.state.map)
            datalayers[layerName].gmpOverlay = gmpOverlay;
            this.setState({ dataLayers: datalayers })
        // }


        }
    }

    handleDataLayerWithFilters = (datalayer) => {

    }

    updateInfoWindow = (pos, content) => {
        let infowindow = this.state.infowindow;
        if (!infowindow) infowindow = new window.google.maps.InfoWindow();
        infowindow.close();
        infowindow.setContent(content);
        infowindow.setPosition(pos);
        infowindow.setOptions({ pixelOffset: new window.google.maps.Size(0, -34) });
        if (this.props.enableLocationPip) infowindow.open(this.state.map);
        this.setState({ infowindow: infowindow })
    }

    removeInfoWindow = () => {
        let infowindow = this.state.infowindow;
        if (infowindow) {
            infowindow.close();
            this.setState({ infowindow: infowindow })
        }
    }

    handleFeatureInfowindow = (feature) => {
        if (!feature) return '';

        let type_display = ''
        if (feature.properties.type === 'status') type_display = 'Area status changed';
        else if (feature.properties.type === 'loss') type_display = 'Area lost';
        else if (feature.properties.type === 'gain') type_display = 'Area gained';

        let grid_id = feature.properties.grid_id,
            province = feature.properties.province,
            suburb = feature.properties.suburb,
            town = feature.properties.town,
            service = feature.properties.service,
            provider = feature.properties.provider,
            status = feature.properties.status,
            type = feature.properties.type,
            // type_display = feature.properties.type_display,
            latitude = feature.properties.latitude,
            longitude = feature.properties.longitude,
            created_at = feature.properties.created_at
        let times = created_at.split('T');
        if (!times || times.length == 0) times = [created_at]
        return `
                <div class='infowindow-header  ${type}' >
                    <strong style="text-transform: uppercase">${this.getDisplayProvider(provider)}</strong>
                </div>
                <div>
                    <div class='infowindow-changeHead'>
                        <span class='infowindow-changeText' style="display:${type_display ? 'inherit' : 'none'}"><b>Change type:&nbsp;</b>${type_display}</span>
                        <div class='infowindow-changeBg ${type}'></div>
                    </div>
                    <div class='infowindow-content'>
                    <b>Service: </b>${this.getDisplayService(service)}<br>
                    <b>Status: </b>${this.getDisplayStatus(status)}<br>
                    <b style="display:${province ? 'inherit' : 'none'}>Province: </b>${province}<br>
                    <b style="display:${suburb ? 'inherit' : 'none'}>Suburb: </b>${suburb}<br>
                    <b style="display:${town ? 'inherit' : 'none'}>Town: </b>${town}<br>
                    <hr class="MuiDivider-root"/>
                    <hr class="MuiDivider-root" style="margin: 10px 0px;"/>
                    <b>Date Updated: </b>${times[0]}<br>
                    </div>
                </div>
                `;
    }

    getDisplayProvider = (name) => {
        if (this.props.applicationData && !this.props.applicationData.loading) {
            let provider = this.props.applicationData.providers.filter(val => val.name === name)
            if (provider && provider.length > 0)
                return provider[0].full_name;
        }
        return name;
    }

    getDisplayStatus = (name) => {
        if (this.props.applicationData && !this.props.applicationData.loading) {
            let status = this.props.applicationData.statuses.filter(val => val.name === name)
            if (status && status.length > 0)
                return status[0].full_name;
        }
        return name;
    }

    getDisplayService = (name) => {
        if (this.props.applicationData && !this.props.applicationData.loading) {
            let service = this.props.applicationData.services.filter(val => val.name === name)
            if (service && service.length > 0)
                return service[0].full_name;
        }
        return name;
    }

    handleMarkerInfowindow = (data) => {
        if(!this.props.enableLocationPip) return
        let content = '<div class="infowindow-content" ><strong>Loading ...</strong></div>'
        if (data && data.services && data.services.length > 0) {
            content = `
                <div class="table-container">
                    <table class="services">
                        <thead>
                            <tr>
                                <th>Provider</th>
                                <th>Service</th>
                                <th>Status</th>
                                <th style='white-space:nowrap;'>API Type</th>
                            </tr>
                            <tr>
                                <th colspan='4' style="background-color: #3f51b5a1; text-transform: capitalize">
                                ${this.props.addressValue}
                                </th>
                            </tr>
                        </thead>`;
            content += "<tbody>"
            for (let item in data.services) {
                if (data.services[item].providers.length > 0) {
                    if (data.services[item].providers.length > 0) {
                        for (let p in data.services[item].providers) {
                            if(!data.services[item].providers[p].error) 
                                content += `<tr><td>${this.getDisplayProvider(data.services[item].providers[p].full_name)}</td>
                                            <td>${this.getDisplayService(data.services[item].type)}</td>                                  
                                            <td>${this.getDisplayStatus(data.services[item].providers[p].status)}</td>
                                            <td>${data.services[item].providers[p].data ? '3rd Party' : '28East'}</td></tr>`;
                            
                                        }
                                    }
                                }
                                
                            }
                           
            content += "</tbody></table></div>"
        } else if (data) {
            content = '<div class="infowindow-content" ><strong>' + data + '</strong></div>';
        } else {
            content = '';
        }
        if (!content) {
            content = '<div class="infowindow-content" ><strong>No services</strong></div>';
        }

        // return `<div class='infowindow-content'>${content}</div>`;
        return `<div>${content}</div>`;
    }

    removeDataLayer = (name) => {
        let datalayers = this.state.dataLayers;
        if (datalayers[name]) {
            if (datalayers[name].layer && typeof datalayers[name].layer.setMap === 'function')
                datalayers[name].layer.setMap(null)
            else if (datalayers[name].layer && typeof datalayers[name].gmpOverlay.setMap === 'function')
                datalayers[name].gmpOverlay.setMap(null)

            // let gmpOverlay = datalayers[name].gmpOverlay;//this.state.gmpOverlay;
            let gmpOverlay = this.state.gmpOverlay;
            if (gmpOverlay) {
                gmpOverlay.setMap(null)
                gmpOverlay.setProps({ layers: [] })
                gmpOverlay.finalize();
                gmpOverlay = null;
            }
        }
        // delete datalayers[name];
        this.setState({ dataLayers: datalayers })
    }

    removeDataLayers = () => {
        let datalayers = this.state.dataLayers;
        let self = this
        datalayers.map(layer => {
            // layer.layer.setMap(null)
            self.removeDataLayer(layer.name)
        })
        this.setState({ dataLayers: [] })
    }


    addWMSLayers(layerIndex = 0, layerName) {
        let google = window.google;
        let map = this.state.map;
        let self = this;
        // if(!google || !map) return;
        let wms = new google.maps.ImageMapType({
            getTileUrl: function (coord, zoom) {

                let s = Math.pow(2, zoom);
                //latlng bounds of the 4 corners of the google tile
                let bottom_left = map.getProjection().fromPointToLatLng(
                    new google.maps.Point(coord.x * 256 / s, (coord.y + 1) * 256 / s)); // bottom left / SW
                let top_right = map.getProjection().fromPointToLatLng(
                    new google.maps.Point((coord.x + 1) * 256 / s, coord.y * 256 / s)); // top right / NE

                let layers = self.props.displayLayers;
                let styles = self.props.displayStyles;
                if (layerName) {
                    layers = layerName.name;
                    styles = layerName.style;
                }

                if(!layers) return '';

                let bbox = bottom_left.lng() + "," + bottom_left.lat() + "," + top_right.lng() + "," + top_right.lat();
                return process.env.REACT_APP_WMS_BASE_URL + 'wms?' +
                    'key='+self.props.gmpKey+'&' +
                    'service=WMS&' +
                    'version=1.1.0&' +
                    'request=GetMap&' +
                    'layers=' + layers + '&' +
                    'styles=' + styles + '&' +
                    'bbox=' + bbox + '&' +
                    'width=256&' +
                    'height=256&' +
                    'srs=EPSG:3857&' +
                    'format=image/png' +
                    '&TRANSPARENT=true' +
                    self.props.viewparams
            },
            tileSize: new google.maps.Size(256, 256),
            isPng: true,
            opacity: 0.6,
            maxZoom: 21,
            minZoom: 0,
            name: layerName ? layerName : 'layerName'
        });

        // map.overlayMapTypes.setAt(0, wms);
        map.overlayMapTypes.setAt(layerIndex, wms);
    }


    addWFSLayers(layerIndex = 0, layerName) {
        let self = this
        let layer = new TileLayer({
            stroked: false,

            // getLineColor: [192, 192, 192],
            getFillColor: layerName && layerName.layerColor ? layerName.layerColor : [255,0,0],
            pickable: true,
            autoHighlight: false,
            dataComparator: oldData => layer.data,
            // _dataDiff: (oldData, newData) => [{startRow: index, endRow: index + 1}],
            onClick: info => {
                self.setTooltip(info)
                // self.updateInfoWindow(new window.google.maps.LatLng(info.lngLat[1], info.lngLat[0]), self.handleFeatureInfowindow(info.object));
            },
            onHover: info => {
                // window.document.pointer
                // self.updateInfoWindow(new window.google.maps.LatLng(info.lngLat[1], info.lngLat[0]), self.handleFeatureInfowindow(info.object));
                self.setTooltip('none')
            },
            onDrag: () => {
                self.setState('none')
            },
            // getLineWidth: f => {
            //     return 1;
            // },
            lineWidthMinPixels: 1,

            getTileData: ({ x, y, z }) => {

                let s = Math.pow(2, z);
                //latlng bounds of the 4 corners of the google tile
                let bottom_left = self.state.map.getProjection().fromPointToLatLng(
                    new window.google.maps.Point(x * 256 / s, (y + 1) * 256 / s)); // bottom left / SW
                let top_right = self.state.map.getProjection().fromPointToLatLng(
                    new window.google.maps.Point((x + 1) * 256 / s, y * 256 / s)); // top right / NE

                let layers = self.props.displayLayers;
                if (layerName) {
                    layers = layerName.name;
                }

                let bbox = bottom_left.lng() + "," + bottom_left.lat() + "," + top_right.lng() + "," + top_right.lat();
                let wfsUrl = process.env.REACT_APP_WMS_BASE_URL + 'wfs?' +
                    'key='+self.props.gmpKey+'&' +
                    'service=wfs&' +
                    'version=2.0.0&' +
                    'request=GetFeature&' +
                    'typeNames=' + layers + '&' +
                    'bbox=' + bbox + '&' +
                    'srsName=EPSG:3857&' +
                    'outputFormat=application/json&' +
                    self.props.viewparams;

                return fetch(wfsUrl)
                    .then(response => response.json())
                    .then(geojson => {
                        return geojson ? geojson.features : [];
                    });
            }
        });

        let datalayers = this.state.dataLayers;
        if(datalayers && !datalayers[layerName]) datalayers[layerName] = {layer: layerName, deckGlLayer: layer}
        else if(datalayers) datalayers[layerName].deckGlLayer = layer;

        let layers = []
        Object.keys(datalayers).forEach((l) => {
            if(datalayers[l].deckGlLayer) layers.push(datalayers[l].deckGlLayer)
        })
        let gmpOverlay = this.state.gmpOverlay;
        if (gmpOverlay) {
            gmpOverlay.setMap(null)
            gmpOverlay.setProps({ layers: [] })
            gmpOverlay.finalize();
            gmpOverlay = null;
        }

        gmpOverlay = new GoogleMapsOverlay({
            layers: layers
        })
        gmpOverlay.setMap(this.state.map)
        // datalayers[layerName].gmpOverlay = gmpOverlay;
        this.setState({ datalayers: datalayers, gmpOverlay: gmpOverlay })

    }

    foreceMapRefresh = () => {
        let google = window.google;
        let map = this.state.map;

        if (!map || !google) return;

        try {
            google.maps.event.trigger(map, 'resize');
            if (map.getZoom) {
                map.setZoom(map.getZoom() - 1);
                map.setZoom(map.getZoom() + 1);
            }
        } catch (e) { console.error(e) }
    }

    addMapLoader = () => {
        let self = this;
        let google = window.google;
        let map = this.state.map;

        google.maps.event.addListener(map, 'bounds_changed', function () {
            self.setState({ mapLoading: true })

            google.maps.event.addListenerOnce(map, 'tilesloaded', function () {
                self.setState({ mapLoading: false })
            });
        });
    }

    addMapClickListener = () => {
        let self = this;
        let google = window.google;
        let map = this.state.map;
        google.maps.event.addListener(map, 'click', function (event) { //drop pin when user clicks on map
            if(self.props.enableLocationPip){
                let pos = new google.maps.LatLng(event.latLng.lat(), event.latLng.lng());
                self.updateMarker(pos);
            }
        });
    }

    onPlaceChange = (place) => {
        if (this.props.onPlaceSearch)
            this.props.onPlaceSearch(place)
    }

    reverseGeocoding = (latitude, longitude) => {
        if (!window.google) throw 'Google Maps not initialised'
        if (!this.googleGeocoder) this.googleGeocoder = new window.google.maps.Geocoder()
        this.googleGeocoder.geocode({ location: { lat: latitude, lng: longitude } }, (results, status) => {
            if (status === 'OK') {
                if (results[0]) {
                    // take the first result
                    results[0].geometry.location = {
                    lat: () => {
                        return latitude
                    },
                    lng: () => {
                        return longitude
                    },
                    }
                    this.onPlaceChange(results[0])
                }
            } else {
                console.error('Geocoder failed due to: ' + status)
            }
        })
    }

    addMarker = (pos, icon = window.location.origin + '/images/locationPin.png') => {
        let self = this;
        let google = window.google;
        let map = this.state.map;
        let marker = new google.maps.Marker({
            position: pos,
            map: map,
            optimized: false,
            draggable: false,
            icon: {
                url: icon,
                scaledSize: new google.maps.Size(30, 37),
                size: new google.maps.Size(243, 288),
                anchor: new google.maps.Point(15, 37)
            },
        });
        marker.addListener('click', function () {
            if(self.props.enableLocationPip)
                self.updateInfoWindow(marker.position, self.handleMarkerInfowindow(self.props.coverageData));
        });
        return marker
    }

    updateMarker = (pos, icon = window.location.origin + '/images/locationPin.png') => {
        let marker = this.state.marker;
        if (marker) {
            marker.setPosition(pos);
            marker.setIcon(icon);
        } else {
            marker = this.addMarker(pos, icon)
        }
        this.reverseGeocoding(marker.position.lat(), marker.position.lng())
        if (this.props.markerChange) this.props.markerChange({ lat: marker.position.lat(), lng: marker.position.lng() })
        if(this.props.enableLocationPip) this.updateInfoWindow(marker.position, this.handleMarkerInfowindow(this.props.coverageData));
        this.setState({ marker: marker })
    }

    updateContent = () => {
        if (this.state.marker && this.props.enableLocationPip)
            this.updateInfoWindow(this.state.marker.position, this.handleMarkerInfowindow(this.props.coverageData));
    }

    setLayerState(layerIndex, layer) {
        if (!this.state.map || !this.state.map.overlayMapTypes) return;

        if (layer.data && layer.checked && layer.notWMS)
            this.handleDataLayers(layer)
        else if (layer.data && !layer.checked && layer.notWMS)
            this.removeDataLayer(layer.name)
        else if (!layer.data && !layer.checked){
            if(!this.props.useVectorTiles)
                this.state.map.overlayMapTypes.setAt(layerIndex, null);
            else
                this.removeDataLayer(layer.name)
        }
        else if (!layer.data && !layer.notWMS && this.props.useVectorTiles) this.addWFSLayers(layerIndex, layer);
        else if (!layer.data && !layer.notWMS) this.addWMSLayers(layerIndex, layer);
    }

    render() {
        return (
            <div className={styles.root}>
                <div id='tooltip' className={styles.tooltip}></div>
                <GoogleMaps
                    id="map"
                    options={this.state.mapOptions}
                    apikey={this.props.gmpKey}
                    onMapLoad={this.handleMapLoad}
                />
            </div>
        );
    }
}

export default DeckGLMapComponent;
