import crypto from 'crypto'

let utils = {}

utils.encode = (value) => {
    return value;//crypto.createHash('sha256').update(value).digest('base64');
}
  

utils.getRandom = (length)  => {
    var result           = '';
    var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    
    for ( var i = 0; i < length; i++ ) {
       result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
 }


utils.auditLogParser = (data, applicationData) => {
    const responseArray = []
    for (let item in data.services) {
            if (data.services[item].providers.length > 0) {
                if (data.services[item].providers.length > 0) {
                    for (let p in data.services[item].providers) {
                        let responseData = {}
                        if(!data.services[item].providers[p].error){
                            responseData.Provider= getDisplayProvider(applicationData, data.services[item].providers[p].full_name)
                            responseData.Service= getDisplayService(applicationData, data.services[item].type)                                
                            responseData.Status = getDisplayStatus(applicationData, data.services[item].providers[p].status)
                            responseData['API Type']= data.services[item].providers[p].data ? '3rd Party' : '28East'}

                            responseArray.push(responseData)
                        }
                    }
                }
            }

    return responseArray
}

const getDisplayProvider = (data, name) => {
    if (data && !data.loading) {
        let provider = data.providers.filter(val => val.name === name)
        if (provider && provider.length > 0)
            return provider[0].full_name;
    }
    return name;
}

const getDisplayStatus = (data, name) => {
    if (data && !data.loading) {
        let status = data.statuses.filter(val => val.name === name)
        if (status && status.length > 0)
            return status[0].full_name;
    }
    return name;
}

const getDisplayService = (data, name) => {
    if (data && !data.loading) {
        let service = data.services.filter(val => val.name === name)
        if (service && service.length > 0)
            return service[0].full_name;
    }
    return name;
}


export default utils;