import React, { Component } from 'react';
import MaterialTable from 'material-table';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles'; 
import { IconButton } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
  
const styles = {
    root: {
        width: '100%',
        height: '100vh',
    },
    filter: {
        zIndex: '100',
        position: 'absolute',
        width: '100px'
    },
    autocomplete: {
        zIndex: '100',
        position: 'absolute',
        width: '100px'
    },
}

class EditableTable extends Component {
    constructor(props) {
        super(props)
        this.state = {
            
        }
    }
 
    validate = (data) => {
        let valid = {success: true, data: data}
        // this.props.columns.forEach(col => { 
        //     if(!data[col.field] && col.isRequired && col.field !== 'active'){
        //         valid.success = false; 
        //     }
        // });
        return valid;    
    }

    onRowAdd = (newData) => {
        return new Promise((resolve,reject) => {
            let valid = this.validate(newData)
            if(valid.success){
                this.props.onRowAdd(newData)    
                resolve(valid);
           } else 
                reject(valid)
        })
    }

    onRowUpdate = (newData, oldData) => {
        return new Promise((resolve,reject) => {
            let valid = this.validate(newData)
            if(valid.success){
                this.props.onRowUpdate(newData, oldData)
                resolve(valid);
            }else 
                reject(valid)
        })
    }

    onRowDelete = (oldData) => {
        return new Promise((resolve,reject) => {
            let valid = this.validate(oldData)
            if(valid.success){ 
                this.props.onRowDelete(oldData)
                resolve(valid);
            }
            else 
                reject(valid)
        }) 
    }

    onEditClicked = (event, rowData) => {
        console.log(rowData)
        this.props.onEditClicked(rowData)
    }

    render() {
        return (
            <MaterialTable
                title={this.props.title ? this.props.title : ''}
                columns={this.props.columns}
                data={this.props.data}
                options={{
                    actionsColumnIndex: -1
                }}  
                actions={this.props.actions ? this.props.actions : []}
                editable={{
                    isEditable: rowData => this.props.user.role === "admin",
                    isDeletable: rowData => this.props.user.role === "admin",
                    onRowAdd: newData => new Promise((resolve, reject) => {
                        this.onRowAdd(newData).then(data => {
                            if(data.success)
                                resolve();
                            else 
                                reject(data.error)
                        }).catch(err => reject(err))
                    }),
                    onRowUpdate: (newData, oldData) => new Promise((resolve, reject) => {
                        this.onRowUpdate(newData, oldData).then(data => {
                            if(data.success)
                                resolve();
                            else 
                                reject(data.error)
                        }) .catch(err => reject(err))
                    }),
                    onRowDelete: oldData => new Promise((resolve, reject) => {
                        this.onRowDelete(oldData).then(data => {
                            if(data.success)
                                resolve();
                            else 
                                reject(data.error)
                        }) .catch(err => reject(err))
                    }),
                }} 
            />
        );
    }

}

EditableTable.propTypes = {
    classes: PropTypes.object.isRequired,
};


export default withStyles(styles)(EditableTable);
 