import React, { Component } from 'react';
import { connect } from "react-redux";
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import CoverageFilter from '../components/CoverageFilter';
import SidePanel from '../components/SidePanel';
import MapComponent from '../components/MapComponent';
import DeckGLMapComponent from '../components/DeckGLMapComponent';
import GreyMapStyle from '../mapstyles/grey.mapstyle'
import DefaultMapStyle from '../mapstyles/default.mapstyle'
import AssassinsCreedMapStyle from '../mapstyles/assasinscreed.mapstyle'
import DarkMapStyle from '../mapstyles/dark.mapstyle'
import { logoutUser, setLayerState } from "../actions";
import UserAvatar from '../components/UserAvatar';
import { LinearProgress } from '@material-ui/core';
import { renderToStaticMarkup } from 'react-dom/server'
import utils from '../Utils/utils';


const styles = {
    root: {
        width: '100%',
        height: '100vh',
        overflow: 'hidden'
    },
    filter: {
        zIndex: '100',
        position: 'absolute',
        width: '100px'

    },
    autocomplete: {
        zIndex: '100',
        position: 'absolute',
        width: '100px'
    },
    avatar: {
        zIndex: '300',
        position: 'absolute !important',
        float: 'right',
        display: 'flex !important',
        padding: '5px !important',
        right: '20px !important',
    }
}
// Viewport settings
const viewState = {
    longitude: -122.41669,
    latitude: 37.7853,
    zoom: 13,
    pitch: 0,
    bearing: 0
};

const singleLayer = true;
const useVectorTiles = false;

class CoverageMap extends Component {
    constructor(props) {
        super(props)
        this.child = React.createRef();
        this.state = {
            layers: [
                {
                    name: `east28:published_comparison`,
                    displayName: 'Coverage Changes',
                    style: '',
                    display: true,
                    checked: true,
                    notWMS: true,
                    colors: [
                        { name: 'Gain', backgroundColor: '#03F149' },
                        { name: 'Status Change', backgroundColor: '#ffeb3b' },
                        { name: 'Loss', backgroundColor: '#F50011' },
                    ],
                    expanded: true,
                    loading: false
                },
                {
                    name: `east28:previous_coverage`,
                    displayName: 'Previous Coverage',
                    style: '',
                    display: true,
                    checked: false,
                    layerColor: [0,0,255,180],
                    colors: [
                        { name: 'Live', backgroundColor: '#598ED3' },
                        { name: 'In Progress', backgroundColor: '#82B7F1' },
                        { name: 'Other', backgroundColor: '#ABCCFF' }
                    ],
                    expanded: true
                },
                {
                    name: `east28:published_coverage`,
                    displayName: 'Current Coverage',
                    style: '',
                    display: true,
                    checked: false,
                    layerColor: [0,255,0,150],
                    colors: [
                        { name: 'Live', backgroundColor: '#54B06A' },
                        { name: 'In Progress', backgroundColor: '#50D272' },
                        { name: 'Other', backgroundColor: '#96EA9E' }
                    ],
                    expanded: true,
                    wfs: true
                },
            ],
            filters: {
                service: '',
                provider: '',
                status: ''
            },
            data: {
                loading: false,
                services: [],
                providers: [],
                statuses: [],
                subscription: {}
            },
            displayLayers: '',
            displayStyles: '',
            viewparams: '',
            defaultParams: 'id:',
            mapStyle: GreyMapStyle.style,
            selectedMapStyle: 'grey',
            coverageData: null,
            selectedService: { name: '', full_name: '' },
            defaultService: { name: '', full_name: '' },
            autocompleteWidget: null,
            addressValue: '',
            drawerOpen: false,
            dataAll: [],
            enableLocationPip: false
        }

    }

    fetchUserData() {
        let self = this;
        let stateData = this.state.data;
        stateData.loading = true;
        this.setState({ data: stateData })

        Promise.all([
            fetch(process.env.REACT_APP_WEBAPI_URL + 'data/services').then(response => response.json()),
            fetch(process.env.REACT_APP_WEBAPI_URL + 'data/providers').then(response => response.json()),
            fetch(process.env.REACT_APP_WEBAPI_URL + 'data/statuses').then(response => response.json())
        ])
            .then((response) => {
                let stateDataAll = this.state.dataAll;
                stateDataAll.statuses = response[2];
                stateDataAll.services = response[0];
                stateDataAll.providers = response[1];

                this.setState({ dataAll: stateDataAll }, state => {
                    self.fetchSubscriptions();
                });
            })
            .catch(err => console.error(err))
    }

    downloadData() {
        let layers = this.state.layers
        layers.forEach((layer, index) => {
            if (layer.notWMS) {
                layers[index].loading = true;
                this.setState({ layers: layers })
                let url = `${process.env.REACT_APP_WMS_BASE_URL}wfs?key=${this.props.user.details.gmp_key}&request=GetFeature&service=WFS&version=1.1.0&typeName=${layer.name}&outputFormat=application/json&viewparams=key:${this.props.user.details.coverage_key};`
                fetch(url)
                    .then(response => response.json())
                    .then(data => {
                        console.log(data)
                        layers[index].data = this.prepareData(data)//JSON.stringify(this.prepareData(data));
                        layers[index].loading = false;
                        this.setState({ layers: layers });
                        this.child.current.setLayerState(index, layers[index])
                    })
                    .catch(err => {
                        layers[index].loading = false;
                        this.setState({ layers: layers })
                        console.error(err)
                    })
            }
        });
        this.setState({ layers: layers })
    }

    downloadLayerData() {
        let layers = this.state.layers
        layers.forEach((layer, index) => {
            if (layer.notWMS) {
                if (!layer.services) layer.services = {}
                if (!layer.data || !layer.services[this.state.filters.service]) this.downloadServiceData(index, this.getNewViewParams())
            }
        });
        this.setState({ layers: layers })
    }

    downloadServiceData(layerIndex, viewparams) {
        let layers = this.state.layers;
        let service = this.state.filters.service;
        if (layers[layerIndex].notWMS) {
            layers[layerIndex].loading = true;
            this.setState({ layers: layers })
            let url = `${process.env.REACT_APP_WMS_BASE_URL}wfs?key=${process.env.REACT_APP_WMS_KEY}&request=GetFeature&service=WFS&version=1.1.0&typeName=${layers[layerIndex].name}&outputFormat=application/json${viewparams}`
            fetch(url)
                .then(response => response.json())
                .then(data => {
                    console.log(data)
                    // if (!layers[layerIndex].data) layers[layerIndex].data= {}
                    if (!layers[layerIndex].services) layers[layerIndex].services = {}
                    layers[layerIndex].services[service] = true//this.prepareData(data)//JSON.stringify(this.prepareData(data));
                    layers[layerIndex].data = this.combineDataServices(layers[layerIndex], this.prepareData(data));
                    layers[layerIndex].loading = false;
                    this.setState({ layers: layers });
                    this.child.current.setLayerState(layerIndex, layers[layerIndex])
                })
                .catch(err => {
                    layers[layerIndex].loading = false;
                    this.setState({ layers: layers })
                    console.error(err)
                })
        }
        this.setState({ layers: layers })
    }

    combineDataServices = (layer, data) => {
        if (!data) return;
        if (!layer.data) return data
        data.features.forEach(function (feature) {
            layer.data.features.push(feature)
        });
        layer.data.totalFeatures = layer.data.features.length;
        return layer.data;
    }

    fetchSubscriptions = () => {
        let self = this;
        let params = {
            token: this.props.user.details.token,
            channel: 'comparison map',
        }
        let searchParams = Object.keys(params).map((key) => {
            return encodeURIComponent(key) + '=' + encodeURIComponent(params[key]);
        }).join('&');

        fetch(process.env.REACT_APP_API_URL + '/getsubscriptions', {
            method: 'post',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: searchParams
        }).then(function (response) {
            return response.json();
        }).then((response) => {
            let stateData = this.state.data;
            stateData.loading = false;

            if (!response.success) {
                console.log("Could not retrieve client subscriptions");
                return
            }
            let service_subscriptions = response.service_subscriptions;
            stateData.subscription = service_subscriptions;

            let services = [], providers = [], statuses = [];
            services = [...new Set(Object.keys(service_subscriptions))]

            stateData.services = this.state.dataAll.services.filter((s) => services.indexOf(s.name) > -1).sort((a, b) => {
                if(a.name.toLowerCase() < b.name.toLowerCase()) return -1;
                if(a.name.toLowerCase() > b.name.toLowerCase()) return 1;
                return 0;
            })
            let defaultService = this.state.selectedService;
            let defaultServices = stateData.services.filter((s) => s.name === 'fibre');
            if (!defaultServices || defaultServices.length === 0) defaultServices = [stateData.services[0]];
            defaultService = defaultServices[0]

            providers.push(...Object.keys(service_subscriptions[defaultService.name]))
            providers = [...new Set(providers)] //dedupe

            for (let service_name of services) {
                for (let provider_name of providers) {
                    if (service_subscriptions[service_name][provider_name]) {
                        statuses.push(...service_subscriptions[service_name][provider_name].statuses)
                    }
                }
            }

            statuses = [...new Set(statuses)] //dedupe

            stateData.providers = this.state.dataAll.providers.filter((p) => providers.indexOf(p.name) > -1).sort((a, b) => {
                if(a.name.toLowerCase() < b.name.toLowerCase()) return -1;
                if(a.name.toLowerCase() > b.name.toLowerCase()) return 1;
                return 0;
            })
            stateData.statuses = this.state.dataAll.statuses.filter((s) => statuses.indexOf(s.name) > -1).sort((a, b) => {
                if(a.name.toLowerCase() < b.name.toLowerCase()) return -1;
                if(a.name.toLowerCase() > b.name.toLowerCase()) return 1;
                return 0;
            })

            let filters = this.state.filters;
            if (defaultService && defaultService.name) filters.service = defaultService.name;
            this.setState({ data: stateData, defaultService: defaultService, selectedService: defaultService, filters: filters }, state => {
                self.updateFilter();
                self.downloadLayerData();
            });
        })
            .catch(err => {
                console.error(err)
            })
    }

    filterProvidersOnSelectedService = (service) => {
        let stateData = this.state.data;
        let providers = [...Object.keys(this.state.data.subscription[service.name])];
        stateData.providers = this.state.dataAll.providers.filter((p) => providers.indexOf(p.name) > -1).sort((a, b) => {
            if(a.name.toLowerCase() < b.name.toLowerCase()) return -1;
            if(a.name.toLowerCase() > b.name.toLowerCase()) return 1;
            return 0;
        })
        this.setState({ data: stateData })
    }

    resetState = () => {
        let state = this.state;
        state.filters = {
            service: '',
            provider: '',
            status: ''
        };
        state.data = {
            loading: false,
            services: [],
            providers: [],
            statuses: []
        };
        state.displayLayers = '';
        state.displayStyles = '';
        state.viewparams = '';
        state.defaultParams = 'id:';
        state.mapStyle = GreyMapStyle.style;
        state.selectedMapStyle = 'grey';
        state.coverageData = null;
        state.selectedService = { name: '', full_name: '' };
        state.autocompleteWidget = null;
        state.addressValue = '';
        state.auditAddress = '';
        state.drawerOpen = false;
        this.setState(state)
    }

    componentDidMount() {
        this.fetchUserData()
        // this.downloadData();
        this.updateLayers();
        // this.updateFilter();
    }

    updateFilter() {
        this.setState({ viewparams: this.getNewViewParams() })
        this.child.current.foreceMapRefresh();
    }

    getNewViewParams() {
        if (!this.state.filters) return;
        let service = this.state.filters.service ? this.state.filters.service : this.state.defaultService.name
        let filter = 'service:' + service + ';'

        let subscriptionProviders = this.state.data.subscription[service]
        let serviceProviders = Object.keys(subscriptionProviders)

        let providers = this.state.filters.provider && this.state.filters.provider !== '' ? this.state.filters.provider.split(',') : serviceProviders
        filter += 'provider:' + (providers.filter(s => s && s !== '' ).map(s => `%27${s}%27`).join('%5C,')).replace(/^,/, '') + ';'

        let statuses = this.state.filters.status && this.state.filters.status !== '' ? this.state.filters.status.split(',') : this.state.data.statuses.map(s => s.name ? s.name : '')
        filter += 'status:' + (statuses.filter(s => s && s !== '' ).map(s => `%27${s}%27`).join('%5C,') + ';').replace(/^,/, '') + ';'

        let viewparams = `&viewparams=`
        viewparams += filter ? filter : '';
        return viewparams;
    }

    // getNewViewParams(){
    //     if (!this.state.filters) return;
    //     let filter = this.state.filters.service ? 'service:' + this.state.filters.service + ';' : 'service:' + this.state.defaultService.name + ';'
    //     if (this.state.filters.provider && this.state.filters.provider.indexOf(',') > -1) {
    //         let value = this.state.filters.provider.split(',')
    //         let tmp = ''
    //         if (value && Array.isArray(value) && value.length > 0) {
    //             value.forEach((v, i) => {
    //                 tmp += v ? `${i != 0 ? '%27 OR provider like %27' : ''}${v}` : ''
    //             });
    //         }
    //         filter += tmp ? 'provider:' + tmp + ';' : ''
    //     } else
    //         filter += this.state.filters.provider ? 'provider:' + this.state.filters.provider + ';' : ''

    //     filter += this.state.filters.status ? 'status:' + this.state.filters.status + ';' : ''

    //     let viewparams = `&viewparams=key:${this.props.user.details.coverage_key};`
    //     viewparams += filter ? filter : '';
    //     return viewparams;
    // }

    updateComparisonFilter() {
        let filter = this.state.filters.service ? 'service:' + this.state.filters.service + ';' : 'service:' + this.state.defaultService.name + ';'
        filter += this.state.filters.provider ? 'provider:' + this.state.filters.provider + ';' : ''
        filter += this.state.filters.status ? 'status:' + this.state.filters.status + ';' : ''

    }

    updateLayers() {
        if (!this.state.layers) return;
        let displayLayers = '';
        let displayStyles = '';
        let urls = []
        let layers = this.state.layers;
        layers.forEach((layer, index) => {
            if ((singleLayer && !useVectorTiles) && layer.checked && !layer.notWMS && (!displayLayers || displayLayers.indexOf(layer.name) < 0)) {
                displayLayers += (displayLayers ? ',' : '') + layer.name
                displayStyles += (displayStyles ? ',' : '') + layer.style
            } else if ((singleLayer && !useVectorTiles) && layer.notWMS) {
                this.child.current.setLayerState(index, layer)
            }
            if (!singleLayer || useVectorTiles) this.child.current.setLayerState(index, layer)
        });
        if (displayLayers) { //invert (for tiling server) layers to match layer order
            let tempX = displayLayers.split(',').reverse();
            displayLayers = tempX.join(',')
            let tempY = displayStyles.split(',').reverse();
            displayStyles = tempY.join(',')
        }
        this.setState({ displayLayers: displayLayers, displayStyles: displayStyles, downloadUrls: urls });

    }


    updateLayer(layer) {
        if (!layer) return;
        let displayLayers = this.state.displayLayers;
        let displayStyles = this.state.displayStyles;
        let urls = []
        let layers = this.state.layers;

        //if checked and not already added to the layer list
        if ((singleLayer && !useVectorTiles) && layer.checked && !layer.notWMS && (!displayLayers || displayLayers.indexOf(layer.name) < 0)) {
            displayLayers += (displayLayers ? ',' : '') + layer.name
            displayStyles += (displayStyles ? ',' : '') + layer.style
        }
        // else remove from display layers if not checked
        else if ((singleLayer && !useVectorTiles) && !layer.checked && !layer.notWMS && (displayLayers && displayLayers.indexOf(layer.name) > -1)) {
            displayLayers = displayLayers.replace(layer.name + ',', '').replace(layer.name + '', '')
            if (layer.style)
                displayStyles = displayStyles.replace(layer.style + ',', '').replace(layer.style + '', '')
        } else if ((singleLayer && !useVectorTiles) && layer.notWMS) {
            this.child.current.setLayerState(layers.findIndex(e => e.name === layer.name), layer)
        }

        if (displayLayers) { //invert (for tiling server) layers to match layer order
            // displayLayers.indexOf(layer.name);
            let layers = this.state.layers;
            // let tempX = displayLayers.split(',').reverse();
            let tempX = displayLayers.split(',');
            if (tempX.length > 1) {
                tempX = tempX.sort((a, b) => {
                    if (layers) {
                        let x = (a && a != -1) ? layers.findIndex(e => e.name === a) : -1;
                        let y = (b && b != -1) ? layers.findIndex(e => e.name === b) : -1;
                        if (x < y) return 1;
                        if (x > y) return -1;
                    }
                    return 0;
                });
            }
            displayLayers = tempX.filter(e => e && e.length > 0).join(',');
            displayLayers = displayLayers
            // let tempY = displayStyles.split(',').reverse();
            let tempY = displayStyles.split(',');
            if (tempY.length > 1) {
                tempY = tempY.sort((a, b) => {
                    if (layers) {
                        let x = (a && a != -1) ? layers.findIndex(e => e.style === a) : -1;
                        let y = (b && b != -1) ? layers.findIndex(e => e.style === b) : -1;
                        if (x > y) return 1;
                        if (x < y) return -1;
                    }
                    return 0;
                });
            }
            displayStyles = tempY.filter(e => e && e.length > 0).join(',');
        }
        this.setState({ displayLayers: displayLayers, displayStyles: displayStyles });

    }

    handleMapLoad = () => {
        if (!singleLayer || useVectorTiles) this.updateLayers();
        else if (this.child && this.child.current) {
            if (useVectorTiles)
                this.child.current.addWFSLayers(this.getLayerIndex());
            else
                this.child.current.addWMSLayers(this.getLayerIndex());
            // this.child.current.initialiseGoogleAutocomplete(this.state.autocompleteWidget);
        }
    }

    getLayerIndex = () => {
        let layerIndex = 0;
        this.state.layers.forEach((layer, index) => {
            if (layer.notWMS) layerIndex = index;
        });
        return layerIndex === 0 ? layerIndex : layerIndex + 1;
    }

    handleToggle = (value) => {
        let layers = this.state.layers;
        let layerIndex = layers.findIndex(l => l.name === value.name);
        if (layerIndex >= 0) layers[layerIndex].checked = !layers[layerIndex].checked;
        this.setState({ layers: [...layers] })
        // this.updateLayers();
        this.updateLayer(layers[layerIndex]);
        if (!singleLayer || useVectorTiles) this.child.current.setLayerState(layerIndex, layers[layerIndex])
        this.child.current.foreceMapRefresh();
    }

    onServiceChange = (val) => {
        let filters = this.state.filters;
        filters.service = val
        this.setState({ filters: filters })
    }
    onProviderChange = (val) => {
        let filters = this.state.filters;
        filters.provider = val
        this.setState({ filters: filters })
    }
    onStatusChange = (val) => {
        let filters = this.state.filters;
        filters.status = val
        this.setState({ filters: filters })
    }

    onFilterClicked = (filter) => {
        let filters = this.state.filters;
        filters.status = filter.status;
        filters.provider = filter.provider;
        filters.service = filter.service && filter.service.length > 0 ? filter.service : this.state.defaultService.name;
        this.setState({ filters: filters })

        this.updateFilter();
        this.downloadLayerData()

        this.state.layers.forEach((layer) => {
            this.child.current.filterDataLayers(filters, layer.name)
        });
    }

    onClearClicked = () => {
        let filters = this.state.filters;
        filters.status = '';
        filters.provider = '';
        filters.service = this.state.defaultService.name;
        this.setState({ filters: filters })
    }

    foreceMapRefresh = () => {
        this.child.current.foreceMapRefresh();
    }

    handleMapStyleChange = (event) => {
        let mapStyle = this.state.mapStyle;
        switch (event.target.value) {
            case 'default': {
                mapStyle = DefaultMapStyle.style;
                break;
            }
            case 'dark2': {
                mapStyle = AssassinsCreedMapStyle.style;
                break;
            }
            case 'dark': {
                mapStyle = DarkMapStyle.style;
                break;
            }
            case 'grey': {
                mapStyle = GreyMapStyle.style;
                break;
            }
            default: {
                mapStyle = GreyMapStyle.style;
                break;
            }
        }
        this.setState({ mapStyle: mapStyle, selectedMapStyle: event.target.value })
        this.child.current.updateMapStyle(mapStyle);
    }

    markerChange = async (pos) => {
        if (!pos) return;
        let self = this;
        let progressComponent = renderToStaticMarkup(<LinearProgress />)
        self.setState({ coverageData: progressComponent }, () => {
            self.child.current.updateContent();
        })

        let responseData = {}
        await fetch(`${process.env.REACT_APP_API_URL}/getfeasibility?latitude=${pos.lat}&longitude=${pos.lng}&key=${this.props.user.details.coverage_key}&channel=comparison map ${process.env.NODE_ENV}`)
            .then(response => response.json())
            .then(data => {
                responseData = data
                self.setState({ coverageData: data }, () => {
                    self.child.current.updateContent();
                })
                
            })
            .catch(err => console.error(err))
            if (responseData.services.length) {
                const request = {
                    location: this.state.auditAddress ?`${this.state.auditAddress?.geometry?.location.lat()}, ${this.state.auditAddress?.geometry?.location.lng()}`: '',
                    formatted_address: this.state.auditAddress? this.state.auditAddress?.formatted_address: '',
                    action: 'comparison.map.getfeasibility'
                }
            }
        
    }

    handleLayerExpand = (item) => {
        let layers = this.state.layers;
        let layerIndex = layers.findIndex((i) => i.name === item.name);
        if (layerIndex > -1) {
            layers[layerIndex].expanded = !layers[layerIndex].expanded;
            this.setState({ layers: layers })
        }
    }

    prepareData = (data) => {
        if (data && data.features && data.features.length > 0) {
            for (let i = 0; i < data.features.length; i++) {
                let feature = data.features[i];
                let p = feature.properties;

                if (!p.province) data.features[i].properties.province = '-';
                if (!p.suburb) data.features[i].properties.suburb = '-';
                if (!p.town) data.features[i].properties.town = '-';
                if (!p.service) data.features[i].properties.service = '-';
                if (!p.status) data.features[i].properties.status = '-';
                if (!p.type) data.features[i].properties.type = '-';
                if (!p.created_at) data.features[i].properties.created_at = '-';
            }
        }
        return data;
    }

    onPlaceSearch = (place) => {
        this.setState({ addressValue: place?.formatted_address })
        this.setState({auditAddress: place})
    }

    provideAutocompleteWidget = (input) => {
        // if(input) this.child.current.initialiseGoogleAutocomplete(input);
        this.setState({ autocompleteWidget: input })
    }

    handleAddressChange = (value) => {
        if (typeof value === 'string') {
            value = value.replace(/ +/g, ' ')
        }
        if (value && value.geometry) {
            this.setState({ addressValue: value })
            this.setState({auditAddress: value})
            this.child.current.handleAddress(value);
        }
    }

    handleLogout = () => {
        const { dispatch } = this.props;
        dispatch(logoutUser());
        // this.props.history.push("/");
    }

    handleManageUsers = (event) => {

    }

    setSelectedService = (service) => {
        this.filterProvidersOnSelectedService(service)
        this.setState({ selectedService: service })
    }

    handleLocationSearchSwitch = (event) => {
        this.setState({ enableLocationPip: !this.state.enableLocationPip });
    }

    render() {
        return (
            <div>
                <SidePanel
                    data={this.state.data}
                    style={styles.filter}
                    handleToggle={this.handleToggle}
                    layers={this.state.layers}
                    filters={this.state.filters}
                    onFilterClicked={this.onFilterClicked}
                    onClearClicked={this.onClearClicked}
                    handleMapStyleChange={this.handleMapStyleChange}
                    selectedMapStyle={this.state.selectedMapStyle}
                    selectedService={this.state.selectedService}
                    handleLayerExpand={this.handleLayerExpand}
                    provideAutocompleteWidget={this.provideAutocompleteWidget}
                    layerControlTitle={"Coverage Layers"}
                    handleAddressChange={this.handleAddressChange}
                    addressValue={this.state.addressValue}
                    user={this.props.user}
                    handleLogout={this.handleLogout}
                    handleManageUsers={this.handleManageUsers}
                    setSelectedService={this.setSelectedService}
                    defaultService={this.state.defaultService}
                    handleLocationSearchSwitch={this.handleLocationSearchSwitch}
                    enableLocationPip={this.state.enableLocationPip}
                />
                <UserAvatar
                    user={this.props.user}
                    handleLogout={this.handleLogout}
                    freeform={true}
                />
                <DeckGLMapComponent
                    filter={this.state.filters}
                    layers={this.state.layers}
                    filters={this.state.filters}
                    handleMapLoad={this.handleMapLoad}
                    displayLayers={this.state.displayLayers}
                    displayStyles={this.state.displayStyles}
                    viewparams={this.state.viewparams}
                    ref={this.child}
                    mapStyle={this.state.mapStyle}
                    markerChange={this.markerChange}
                    coverageData={this.state.coverageData}
                    onPlaceSearch={this.onPlaceSearch}
                    applicationData={this.state.data}
                    gmpKey={this.props.user.details.gmp_key}
                    useVectorTiles={useVectorTiles}
                    enableLocationPip={this.state.enableLocationPip}
                    addressValue={this.state.addressValue}
                    user={this.props.user}
                    auditAddress={this.state.auditAddress}
                />
            </div>
        );
    }
}


CoverageMap.propTypes = {
    classes: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
    return {
        user: state.auth.user
    };
}

export default withStyles(styles)(connect(mapStateToProps)(CoverageMap));
// export default withStyles(styles)(CoverageMap);
