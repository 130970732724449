import React, { Component } from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import styles from './styles.module.css'
import ListSubheader from '@material-ui/core/ListSubheader';

import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';
import FilterListIcon from '@material-ui/icons/FilterList';
import Chip from '@material-ui/core/Chip';

import Autocomplete from '@material-ui/lab/Autocomplete';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';

import InputBase from '@material-ui/core/InputBase';
import MenuIcon from '@material-ui/icons/Menu';
import ClearIcon from '@material-ui/icons/Clear';
import SearchIcon from '@material-ui/icons/Search';
import InputAdornment from "@material-ui/core/InputAdornment";
import CircularProgress from '@material-ui/core/CircularProgress';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import Avatar from '@material-ui/core/Avatar';
import Menu from '@material-ui/core/Menu';
import AdminActions from '../AdminActions';  
import Fade from '@material-ui/core/Fade';

const icon = < CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = < CheckBoxIcon fontSize="small" />;

class CoverageFilter extends Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedService: props.defaultService,
            addressValue: '',
            addressClearable: false,
            filter: {
                service: '',
                provider: '',
                status: ''
            },
            isMenuOpen: false,
            displayFilter: false
        }
    }

    componentDidMount = () => {
        this.props.provideAutocompleteWidget(document.getElementById('pac-input'));
    }

    handleToggle = (value) => {
        this.props.handleToggle(value)
    }

    onServiceChange = (event, value) => {
        let filter = this.state.filter
        console.log(value)
        filter.service = value ? value.name : '';
        this.setState({ filter: filter, selectedService: value });
        this.onFilterClicked();
    }

    onProviderChange = (event, value) => {
        let filter = this.state.filter
        if (value && Array.isArray(value)) {
            filter.provider = '';
            value.forEach((v, i) => {
                filter.provider += ',' + v.name
            });
        }
        this.setState({ filter: filter });
        this.onFilterClicked();
    }

    onStatusChange = (event, value) => {
        let filter = this.state.filter
        console.log(value)
        filter.status = value ? value.name : '';
        this.setState({ filter: filter });
        this.onFilterClicked();
    }

    onFilterClicked = (event) => {
        this.props.onFilterClicked(this.state.filter)
    }

    onClearClicked = (event) => {
        this.setState({
            filter: {
                service: '',
                provider: '',
                status: ''
            }
        });
        this.props.onClearClicked()
    }

    handleExpandClick = (item) => {
        this.props.handleLayerExpand(item)
    }

    handleAddressChange = (event) => {
        let addressClearable = this.state.addressClearable
        addressClearable = event.target.value ? true : false;
        console.log('value: ' + event.target.value + ' = ' + addressClearable);
        if (this.props.handleAddressChange) this.props.handleAddressChange(event.target.value);
        this.setState({ addressClearable: addressClearable });
    }

    clearAddressField = (event) => {
        let addressClearable = this.state.addressClearable
        addressClearable = false;
        this.setState({ addressClearable: addressClearable, addressValue: '' });
        if (this.props.handleAddressChange) this.props.handleAddressChange('');
    }

    handleProfileMenuOpen = (event) => {
        this.setState({ isMenuOpen: !this.state.isMenuOpen, anchorEl: event.currentTarget })
    }

    handleMenuClose = (event) => {
        this.setState({ isMenuOpen: false })
    }

    handleLogout = (event) => {
        this.handleMenuClose(event);
        this.props.handleLogout();
    }

    handleManageUsers = (event) => {
        this.handleMenuClose(event);
        this.props.handleManageUsers();
    }
    handleFilterToggle = (event) => {
        this.setState({ displayFilter: !this.state.displayFilter })
    }

    render() {
        return (
            <div className={styles.root} >
                <div >
                    <IconButton
                        className={styles.avatar}
                        edge="end"
                        aria-label="Current user"
                        aria-controls="account-menu"
                        aria-haspopup="true"
                        onClick={this.handleProfileMenuOpen}
                        color="inherit"
                    >
                        <Avatar alt={this.props.user ? this.props.user.name : "User"} src={this.props.user ? this.props.user.photoURL : ''} />
                    </IconButton>
                    <Menu
                        className={styles.accountMenu}
                        anchorEl={this.state.anchorEl}
                        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                        id="account-menu"
                        keepMounted
                        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                        open={this.state.isMenuOpen}
                        onClose={this.handleMenuClose}
                    >
                        {this.props.user.details.role === "admin" &&
                            <MenuItem onClick={this.handleManageUsers}>Manage Users</MenuItem>
                        }
                        <MenuItem onClick={this.handleLogout}>Logout</MenuItem>
                    </Menu>
                </div>
                <Paper id='pac-container' className={styles.search} >
                    <TextField id='pac-input'
                        value={this.props.addressValue}
                        className={styles.searchInput}
                        placeholder="Search Google Maps"
                        onChange={this.handleAddressChange}
                        InputProps={
                            {
                                'aria-label': 'search google maps',
                                endAdornment: (
                                    <InputAdornment > {
                                        !this.state.addressClearable ?
                                            <IconButton >
                                                <SearchIcon />
                                            </IconButton>
                                            : <IconButton onClick={this.clearAddressField} >
                                                <ClearIcon />
                                            </IconButton>
                                    }
                                        <Divider orientation="vertical" className={styles.devider} />
                                        <IconButton onClick={this.handleFilterToggle} >
                                            <FilterListIcon />
                                        </IconButton>
                                    </InputAdornment>
                                ),
                                startAdornment: (
                                    <InputAdornment >
                                        <AdminActions
                                            user={this.props.user}
                                            className={styles.dark}
                                        />
                                    </InputAdornment >
                                )
                            }
                        }
                    /> 
                </Paper > 
                <Fade  in={this.state.displayFilter}>
                    <Paper className={styles.filter} > 
                        <Autocomplete id="field1"
                            autoHighlight autoSelect value={this.state.selectedService}
                            loading={this.props.data.loading}
                            className={styles.filterItem}
                            options={this.props.data.services}
                            getOptionLabel={option => option.full_name}
                            style={
                                { width: 200 }
                            }
                            onChange={this.onServiceChange}
                            clearOnEscape filterSelectedOptions defaultValue={this.props.data.services.filter((f) => f.name === 'fibre')}
                            renderInput={
                                params => (<TextField {...params}
                                    placeholder="Services"
                                    fullWidth />
                                )
                            }
                        />
                        <Divider orientation="vertical" className={styles.devider} />
                        <Autocomplete id="field2"
                            multiple size="small"
                            loading={this.props.data.loading}
                            className={styles.providerItem}
                            options={this.props.data.providers}
                            getOptionLabel={
                                (option) => option && option.full_name ? option.full_name : option
                            }
                            style={
                                { width: 200 }
                            }
                            onChange={this.onProviderChange}
                            clearOnEscape
                            disableCloseOnSelect
                            renderTags={(value, getTagProps) =>
                                <Chip label={'Filtered'} className={styles.chip} />
                            }
                            renderOption={(option, { selected }) => (
                                <React.Fragment >
                                    <Checkbox
                                        icon={icon}
                                        checkedIcon={checkedIcon}
                                        style={{ marginRight: 8 }}
                                        checked={selected}
                                    />
                                    {option.full_name}
                                </React.Fragment >
                            )}
                            renderInput={
                                params => (<TextField
                                    {...params}
                                    placeholder="Providers"
                                    fullWidth />
                                )} />
                        <Divider orientation="vertical" className={styles.devider} />
                        <Autocomplete id="field3"
                            loading={this.props.data.loading}
                            className={styles.filterItem}
                            options={this.props.data.statuses}
                            getOptionLabel={option => option.full_name}
                            style={
                                { width: 200 }
                            }
                            onChange={this.onStatusChange}
                            clearOnEscape
                            filterSelectedOptions
                            renderInput={
                                params => (
                                    <TextField {...params}
                                        placeholder="Statuses"
                                        fullWidth />
                                )}
                        />
                    </Paper > 
                </Fade>
                <Paper className={styles.layers} >
                    <List subheader={
                        <ListSubheader component="div" id="nested-list-subheader" >
                            {'Filter'}
                         </ListSubheader>
                    } >
                    <Divider />
                        <ListItem key={'service'}
                            role={undefined}
                            dense >
                            <ListItemIcon >
                                 <FilterListIcon />
                            </ListItemIcon>   
                            <Autocomplete id="field1"
                                autoHighlight autoSelect value={this.state.selectedService}
                                loading={this.props.data.loading}
                                className={styles.filterItem}
                                options={this.props.data.services}
                                getOptionLabel={option => option.full_name}
                                style={
                                    { width: 200 }
                                }
                                onChange={this.onServiceChange}
                                clearOnEscape filterSelectedOptions defaultValue={this.props.data.services.filter((f) => f.name === 'fibre')}
                                renderInput={
                                    params => (<TextField {...params}
                                        placeholder="Services"
                                        fullWidth />
                                    )
                                }
                            />
                        </ListItem > 
                        <ListItem key={'provider'}
                            role={undefined}
                            dense >
                            <ListItemIcon >
                                 <FilterListIcon />
                            </ListItemIcon>  
                            <Autocomplete id="field2"
                                multiple size="small"
                                loading={this.props.data.loading}
                                className={styles.providerItem}
                                options={this.props.data.providers}
                                getOptionLabel={
                                    (option) => option && option.full_name ? option.full_name : option
                                }
                                style={
                                    { width: 200 }
                                }
                                onChange={this.onProviderChange}
                                clearOnEscape
                                disableCloseOnSelect
                                renderTags={(value, getTagProps) =>
                                    // <Chip label={'Filtered'} className={styles.chip} />
                                    value.map((option, index) => (
                                        <Chip variant="outlined" label={option.full_name} {...getTagProps({ index })} />
                                      ))
                                }
                                renderOption={(option, { selected }) => (
                                    <React.Fragment >
                                        <Checkbox
                                            icon={icon}
                                            checkedIcon={checkedIcon}
                                            style={{ marginRight: 8 }}
                                            checked={selected}
                                        />
                                        {option.full_name}
                                    </React.Fragment >
                                )}
                                renderInput={
                                    params => (<TextField
                                        {...params}
                                        placeholder="Providers"
                                        fullWidth />
                                    )} />
                        </ListItem > 
                        <ListItem key={'status'}
                            role={undefined}
                            dense >
                            <ListItemIcon >
                                 <FilterListIcon />
                            </ListItemIcon>  
                            <Autocomplete id="field3"
                                loading={this.props.data.loading}
                                className={styles.filterItem}
                                options={this.props.data.statuses}
                                getOptionLabel={option => option.full_name}
                                style={
                                    { width: 200 }
                                }
                                onChange={this.onStatusChange}
                                clearOnEscape
                                filterSelectedOptions
                                renderInput={
                                    params => (
                                        <TextField {...params}
                                            placeholder="Statuses"
                                            fullWidth />
                                    )}
                            />
                        </ListItem > 
                    </List>
                </Paper > 
                <Paper className={styles.layers} >
                    <List subheader={
                        <ListSubheader component="div" id="nested-list-subheader" >
                            {this.props.layerControlTitle ? this.props.layerControlTitle : 'Layer control'
                        } </ListSubheader>
                    } >
                        <Divider />
                        {this.props.layers.map(value => {
                            return value.display && (
                                <div key={'layer-control-child-container-' + value.name} >
                                    <ListItem key={value.name}
                                        role={undefined}
                                        dense >
                                        <ListItemIcon >
                                            {value.loading ?
                                                <CircularProgress className={styles.loader} />
                                                : <Checkbox
                                                    onClick={
                                                        () => { this.handleToggle(value) }
                                                    }
                                                    edge="start"
                                                    checked={value.checked}
                                                    tabIndex={-1}
                                                    disableRipple
                                                    inputProps={
                                                        { 'aria-labelledby': value.displayName }
                                                    }
                                                />
                                            }
                                        </ListItemIcon>
                                        <ListItemText id={`checkbox-list-label-${value.name}`} primary={value.displayName} />
                                        <IconButton
                                            onClick={() => { this.handleExpandClick(value) }} >
                                            {value.expanded ? < ExpandLess /> : < ExpandMore />}
                                        </IconButton>
                                    </ListItem >
                                    <Collapse in={value.expanded}
                                        timeout="auto"
                                        unmountOnExit > {
                                            value.colors.map(c => {
                                                return <Box
                                                    key={value.name + '_tab_' + c.name}
                                                    p={1}
                                                    aria-label={c.name}
                                                    style={
                                                        {
                                                            backgroundColor: c.color,
                                                            fontSize: '8pt',
                                                            fontWeight: 'bolder',
                                                            color: 'white'
                                                        }
                                                    } > {c.name} </Box>
                                            })
                                        } </Collapse>
                                </div >
                            )
                        })
                        }
                    </List>
                </Paper > 
            </div>
        );
    }
}
export default CoverageFilter;