import React, { Component } from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import styles from './styles.module.css'
import ListSubheader from '@material-ui/core/ListSubheader';

import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';
import FilterListIcon from '@material-ui/icons/FilterList';
import Chip from '@material-ui/core/Chip';

import Autocomplete from '@material-ui/lab/Autocomplete';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';

import InputBase from '@material-ui/core/InputBase';
import MenuIcon from '@material-ui/icons/Menu';
import ClearIcon from '@material-ui/icons/Clear';
import SearchIcon from '@material-ui/icons/Search';
import InputAdornment from "@material-ui/core/InputAdornment";
import CircularProgress from '@material-ui/core/CircularProgress';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import Avatar from '@material-ui/core/Avatar';
import Menu from '@material-ui/core/Menu';
import AdminActions from '../AdminActions';

const icon = < CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = < CheckBoxIcon fontSize="small" />;

class Filter extends Component {

    constructor(props) {
        super(props);
        this.state = {

        }
    }  
    render() {
        return (
            <List subheader={
                <ListSubheader disableSticky component="div" id="nested-list-subheader" >
                    {'Filter'}
                </ListSubheader>
            } >
                <ListItem key={'service'}
                    role={undefined}
                    dense >
                    <ListItemIcon >
                        <FilterListIcon />
                    </ListItemIcon>
                    <Autocomplete id="field1"
                        autoHighlight 
                        // autoSelect 
                        value={this.props.selectedService}
                        loading={this.props.data.loading}
                        className={styles.filterItem}
                        options={this.props.data.services}
                        getOptionLabel={option => option.full_name}
                        style={
                            { width: '100%' }
                        }
                        onChange={this.props.onServiceChange}
                        clearOnEscape 
                        filterSelectedOptions 
                        // defaultValue={this.props.data.services.filter((f) => f.name === 'fibre')}
                        renderInput={
                            params => (<TextField {...params}
                                placeholder="Services"
                                fullWidth />
                            )
                        }
                    />
                </ListItem >
                <ListItem key={'provider'}
                    role={undefined}
                    dense >
                    <ListItemIcon >
                        <FilterListIcon />
                    </ListItemIcon>
                    <Autocomplete id="field2"
                        multiple size="small"
                        loading={this.props.data.loading}
                        className={styles.providerItem}
                        options={this.props.data.providers}
                        getOptionLabel={
                            (option) => option && option.full_name ? option.full_name : option
                        }
                        style={
                            { width: '100%' }
                        }
                        onChange={this.props.onProviderChange}
                        clearOnEscape
                        // disableCloseOnSelect
                        renderTags={(value, getTagProps) =>
                            // <Chip label={'Filtered'} className={styles.chip} />
                            value.map((option, index) => (
                                <Chip variant="outlined" label={option.full_name} {...getTagProps({ index })} />
                            ))
                        }
                        renderOption={(option, { selected }) => (
                            <React.Fragment >
                                <Checkbox
                                    icon={icon}
                                    checkedIcon={checkedIcon}
                                    style={{ marginRight: 8 }}
                                    checked={selected}
                                />
                                {option.full_name}
                            </React.Fragment >
                        )}
                        renderInput={
                            params => (<TextField
                                {...params}
                                placeholder="Providers"
                                fullWidth />
                            )} />
                </ListItem >
                <ListItem key={'status'}
                    role={undefined}
                    dense >
                    <ListItemIcon >
                        <FilterListIcon />
                    </ListItemIcon>
                    <Autocomplete id="field3"
                        loading={this.props.data.loading}
                        className={styles.filterItem}
                        options={this.props.data.statuses}
                        getOptionLabel={option => option.full_name}
                        style={
                            { width: '100%' }
                        }
                        onChange={this.props.onStatusChange}
                        clearOnEscape
                        filterSelectedOptions
                        renderInput={
                            params => (
                                <TextField {...params}
                                    placeholder="Statuses"
                                    fullWidth />
                            )}
                    />
                </ListItem >
            </List>
        );
    }
}
export default Filter;