import React, { Component } from 'react';
import { connect } from "react-redux";
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles'; 
import EditableTable from '../components/EditableTable';
import { logoutUser, getClients, addClient, updateClient, deleteClient } from "../actions"; 
import { IconButton, Avatar, Menu, MenuItem, Select, FormControl, InputLabel, TextField } from '@material-ui/core';
import NavBar from '../components/NavBar';

const styles = {
    content: {
        margin: '20pt'
    }
}
  
class ManageClients extends Component {
    constructor(props) {
        super(props)
        this.getAllClients();
        let self = this;
        this.state = {
            columns: [  
                {title: 'Name', field: 'full_name', editable:'onAdd'},
                {title: 'Coverage API Key', field: 'coverage_key'},
                {title: 'Google Maps API Key', field: 'gmp_key'}, 
                {title: 'Token', field: 'token'},
                {title: 'Active', field: 'active', type: 'boolean'},
            ],
            data: this.props.users,
            coverage_key: '',
            userState: ''
        }
    }
  
    getAllClients = () => {
        const { dispatch } = this.props;
        dispatch(getClients());
    };

    onRowAdd = (newData) => {
        const { dispatch } = this.props;
        newData.name = newData.full_name.trim().toLowerCase().replace(/[.*+?^${}()|[\]\\]/g, '');
        newData.name = newData.name.replace(/\s/g, '')
        dispatch(addClient(newData, this.props.user))
        this.setState({userState: 'added'})
        this.getAllClients();
    }
    
    onRowUpdate = (newData, oldData) => {
        const { dispatch } = this.props;
        newData.name = oldData.name
        dispatch(updateClient(newData,oldData, this.props.user))
        this.setState({userState: 'updated'})
        this.getAllClients();
    }

    onRowDelete = (oldData) => {
        const { dispatch } = this.props;
        dispatch(deleteClient(oldData, this.props.user))
        this.setState({userState: 'deleted'})
        this.getAllClients();
    }

    handleLogout = () => {
        const { dispatch } = this.props;
        dispatch(logoutUser());
    }

    render () {
        return ( 
            <div style={styles.root}>
                <NavBar
                    title={'Manage Clients'}
                    user={this.props.user}
                    handleLogout={this.handleLogout}
                />
                <div style={styles.content}>
                    <EditableTable
                        columns={this.state.columns}
                        data={this.props.clients}
                        onRowAdd={this.onRowAdd}
                        onRowUpdate={this.onRowUpdate}
                        onRowDelete={this.onRowDelete}
                        user={this.props.user}
                        clients={this.props.clients}
                    />
                </div>
            </div>
        );
    }

}

ManageClients.propTypes = {
    classes: PropTypes.object.isRequired,
};

function mapStateToProps(state) {  
    let editableClients = []
    if(state.clients && state.clients.data)
        editableClients = state.clients.data.filter((e) => {return e.name !== 'other'})
    return {
        user: state.auth.user, 
        clients: editableClients, 
    };
}

export default withStyles(styles)(connect(mapStateToProps)(ManageClients));