import React, { Component } from 'react';
import { connect } from "react-redux";
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import EditableTable from '../components/EditableTable';
import { getClients, getUsers, addUser, updateUser, deleteUser, logoutUser, sendUserPasswordReset, signUpUser } from "../actions";
import { MenuItem, Select, FormControl, TextField, Snackbar } from '@material-ui/core';
import NavBar from '../components/NavBar';
import { Alert } from '@material-ui/lab';

const styles = {
    content: {
        margin: '20pt'
    },
    paper: {
        position: 'absolute',
        width: 400,
        // backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        // boxShadow: theme.shadows[5],
        // padding: theme.spacing(2, 4, 3),
    },
    modalStyle: {
        // top:
    }
}

class ManageUsers extends Component {
    constructor(props) {
        super(props)
        this.getClientsUsers();
        let self = this;
        this.state = {
            columns: [
                { title: 'Email', field: 'email', editable: 'onAdd' },
                { title: 'Full name', field: 'full_name' },
                {
                    title: 'Client', field: 'client',
                    lookup: this.props.clientLookup,
                    // initialEdtValue: 'other',
                    emptyValue: () => 'Other',

                },
                { title: 'Coverage API Key', field: 'coverage_key' },
                { title: 'Google Maps API Key', field: 'gmp_key' },
                {
                    title: 'Role', field: 'role',
                    lookup: { admin: 'Admin', viewer: 'Viewer' },
                    initialEdtValue: 'viewer',
                    emptyValue: () => 'Viewer',
                },
                { title: 'Token', field: 'token' },
                { title: 'Active', field: 'active', type: 'boolean' },
            ],
            actions: [
                {
                    icon: 'email',
                    tooltip: 'Reset Password',
                    onClick: (event, rowData) => {
                        const { dispatch } = this.props;
                        dispatch(sendUserPasswordReset(rowData.email))
                        this.setState({message: 'Password reset email has been sent.'})
                    }
                }
            ],
            data: this.props.users,
            coverage_key: '',
            gmp_key: '',
            token: '',
            message: null
        }
    }

    getClientDetails = (clientName) => {
        let details = {}
        if (clientName) {
            let clients = this.props.clients;
            clients.forEach(client => {
                if (client.name === clientName) {
                    details = client
                }
            });
        } else {
            details.coverage_key = ''
            details.gmp_key = ''
            details.token = ''
        }
        this.setState({message: 'Clients loaded'})
        return details
    }

    fillInUserDetailsFromClient = (client) => {
        let columns = this.state.columns;
        let details = this.getClientDetails(client)
        details.currentSelectedClient = client
        details.currClientLoaded = false;
        console.log('details')
        this.setState(details)

    }

    updateUserDetailsFromClient = (messageChange) => {
        // let columns = this.state.columns;
        // let details = this.getClientDetails(clientName)
        // details.currentSelectedClient = clientName
        // details.currClientLoaded = false;
        // console.log('details')
        // this.setState(details)
        this.setState({message: messageChange})
    }

    getSelectValue = (val) => {
        let clientName = val.target ? val.target.value : val
        console.log('val')
        console.log(clientName)
        let details = this.getClientDetails(clientName)
        // if (!this.state.currClientLoaded) {
        //     details.currClientLoaded = true;
        // }
        if (details) return details.name
        return clientName
    }

    componentWillReceiveProps() {
        let columns = this.state.columns;
        let clients = this.props.clients
        let self = this;
        columns.forEach(col => {
            if (col.field === 'client') {
                col.lookup = this.props.clientLookup
                console.log(col.lookup)
                col.editComponent = props => (
                    <FormControl >
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={props.value && props.value.target ? self.getSelectValue(props.value) : (props.value && props.value !== '' ? props.value : 'other')}
                            onChange={(e) => {
                                self.fillInUserDetailsFromClient(e.target.value)
                                props.onChange(e.target.value)
                            }}
                        >
                            {
                                self.props.clients.map(client => {
                                    return (client.active &&
                                        <MenuItem key={client.name} value={client.name}>{client.full_name}</MenuItem>
                                    )
                                })
                            }
                        </Select>
                    </FormControl>
                )
            } else if (col.field === 'coverage_key' || col.field === 'gmp_key' || col.field === 'token') {
                col.editComponent = props => (
                    <TextField
                        label={col.title}
                        value={self.state[col.field] ? self.state[col.field] : props.value}
                        onChange={(e) => {
                            let x = {}
                            x[col.field] = e.target.value
                            self.setState(x)
                        }}
                    />)
            }
        })
        this.setState({ columns: columns })
    }

    componentDidMount() {
        this.setState({ data: this.props.users });
    }

    getAllUsers = () => {
        const { dispatch } = this.props;
        dispatch(getUsers());
    };

    getClientsUsers = () => {
        const { dispatch } = this.props;
        dispatch(getClients());
        dispatch(getUsers());
    };

    onRowAdd = (newData) => {
        const { dispatch } = this.props;
        let messageChange = 'User added. A password reset email has been sent to the email.'

        if (this.state.coverage_key !== '' && (!newData.coverage_key || newData.coverage_key === "")) {
            newData.coverage_key = this.state.coverage_key;
        }
        if (this.state.gmp_key !== '' && (!newData.gmp_key || newData.gmp_key === "")) {
            newData.gmp_key = this.state.gmp_key;
        }
        if (this.state.token !== '' && (!newData.token || newData.token === "")) {
            newData.token = this.state.token;
        }
        if (!newData.role) newData.role = 'viewer'

        
        this.setState({message: 'User added. A password reset email has been sent to the email.'}, () => {
            dispatch(addUser(newData, this.props.user))
        })
        this.getAllUsers();
        this.updateUserDetailsFromClient(messageChange)
    }

    onRowUpdate = (newData, oldData) => {
        const { dispatch } = this.props;
        let messageChange = 'User Updated'

        if (this.state.coverage_key !== oldData.coverage_key && newData.coverage_key === oldData.coverage_key && (this.state.coverage_key !== "" && oldData.coverage_key !== "" && newData.coverage_key !== "")) {
            newData.coverage_key = this.state.coverage_key;
        }
        if (this.state.gmp_key !== oldData.gmp_key && newData.gmp_key === oldData.gmp_key && (this.state.gmp_key !== "" && oldData.gmp_key !== "" && newData.gmp_key !== "")) {
            newData.gmp_key = this.state.gmp_key;
        }
        if (this.state.token !== oldData.token && newData.token === oldData.token && (this.state.token !== "" && oldData.token !== "" && newData.token !== "")) {
            newData.token = this.state.token;
        }

        this.setState({message: 'User Updated'}, () => {
          dispatch(updateUser(newData, oldData, this.props.user))
        })
        this.getAllUsers();
        this.updateUserDetailsFromClient(messageChange)
    }

    onRowDelete = (oldData) => {
        const { dispatch } = this.props;
        let messageChange = 'User Deleted'
        this.setState({message: 'User Deleted'}, () => {
            dispatch(deleteUser(oldData, this.props.user))
        })
        this.getAllUsers();
        this.updateUserDetailsFromClient(messageChange)
    }

    handleLogout = () => {
        const { dispatch } = this.props;
        dispatch(logoutUser());
    }
    handleModelOpen = (rowData) => {
        console.log(rowData)
        this.setState({ modelOpen: true, rowData: rowData })
    }
    handleModelClose = () => {
        this.setState({ modelOpen: false })
    }

    render() {
        return (
            <div style={styles.root}>
                <NavBar
                    title={'Manage Users'}
                    user={this.props.user}
                    handleLogout={this.handleLogout}
                />
                <div style={styles.content}>
                    <EditableTable
                        columns={this.state.columns}
                        data={this.props.users}
                        onRowAdd={this.onRowAdd}
                        onRowUpdate={this.onRowUpdate}
                        onRowDelete={this.onRowDelete}
                        user={this.props.user}
                        clients={this.props.clients}
                        onEditClicked={this.handleModelOpen}
                        actions={this.state.actions}
                    />
                </div>

                <Snackbar
                    open={!!this.state.message}
                    autoHideDuration={6000}
                    onClose={() => this.setState({message: null})}
                    anchorOrigin={{ horizontal: 'right', vertical:'bottom' }}>
                    <Alert onClose={() => this.setState({message: null})} severity="success">
                       {this.state.message}
                    </Alert>
                </Snackbar>

            </div>
        );
    }

}

ManageUsers.propTypes = {
    classes: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
    let clientLookup = {}
    if (state.clients && state.clients.data && state.clients.data.length > 0) {
        state.clients.data.forEach(client => {
            if (client.active === true) {
                clientLookup[client.name] = client.full_name
            }
        });
    }

    return {
        user: state.auth.user,
        users: state.users.doc,
        clients: state.clients ? state.clients.data : [],
        clientLookup: clientLookup ? clientLookup : [],
    };
}

export default withStyles(styles)(connect(mapStateToProps)(ManageUsers));