import { appFirebase, db } from "../services/Firebase";
import auth, { signUpUser } from "./auth";
import { logAction } from "./logger";

export const CREATE_USER_REQUEST = "CREATE_USER_REQUEST";
export const CREATE_USER_SUCCESS = "CREATE_USER_SUCCESS";
export const CREATE_USER_FAILURE = "CREATE_USER_FAILURE";

export const GET_USER_REQUEST = "GET_USER_REQUEST";
export const GET_USER_SUCCESS = "GET_USER_SUCCESS";
export const GET_USER_FAILURE = "GET_USER_FAILURE";

export const UPDATE_USER_REQUEST = "UPDATE_USER_REQUEST";
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";
export const UPDATE_USER_FAILURE = "UPDATE_USER_FAILURE";

export const DELETE_USER_REQUEST = "DELETE_USER_REQUEST";
export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS";
export const DELETE_USER_FAILURE = "DELETE_USER_FAILURE";

const requestCreateUser = () => {
    return {
        type: CREATE_USER_REQUEST
    };
};
const recieveCreateUser = obj => {
    return {
        type: CREATE_USER_SUCCESS,
        obj
    };
};
const createUserError = (error) => {
    return {
        type: CREATE_USER_FAILURE,
        error
    };
};

const requestGET = () => {
    return {
        type: GET_USER_REQUEST
    };
};
const recieveGetUser = obj => {
    return {
        type: GET_USER_SUCCESS,
        obj
    };
};
const GetUserError = (error) => {
    return {
        type: GET_USER_FAILURE,
        error
    };
};

const requestUpdateUser = () => {
    return {
        type: UPDATE_USER_REQUEST
    };
};
const recieveUpdateUser = obj => {
    return {
        type: UPDATE_USER_SUCCESS,
        obj
    };
};
const updateUserError = (error) => {
    return {
        type: UPDATE_USER_FAILURE,
        error
    };
};

const requestDeleteUser = () => {
    return {
        type: DELETE_USER_REQUEST
    };
};
const recieveDeleteUser = obj => {
    return {
        type: DELETE_USER_SUCCESS,
        obj
    };
};
const deleteErrorUser = (error) => {
    return {
        type: DELETE_USER_FAILURE,
        error
    };
};

export const addUser = (data, user) => dispatch => {
    dispatch(requestCreateUser());
    try {
        if (!data.email) {
            throw ({ error: "email not specified" });
        }
        let ref = db.collection("users").doc(data.email);
        let doc = ref.set(data)
        dispatch(signUpUser(data.email))
        dispatch(recieveCreateUser(doc))
         const info = {
            data: data
        }
        if(user.details.email) logAction(user.details, 'usermanagement.add', 'New user successfully added', info)
    } catch (error) {
        logAction(user.details, 'usermanagement.add', 'Failed to create new user')
        dispatch(createUserError(error));
    };
};

export const deleteUser = (data, user) => dispatch => {
    dispatch(requestDeleteUser());
    try {
        if (!data.email) {
            throw ({ error: "email not specified" });
        }

        let ref = db.collection("users").doc(data.email);
        let doc = ref.delete();
        const info = {
            data: data
        }
        logAction(user.details, 'usermanagement.delete', 'User successfully deleted' , info)
        dispatch(recieveDeleteUser(doc))

    } catch (error) {
        logAction(user.details, 'usermanagement.delete', 'Failed to delete user')
        dispatch(updateUserError(error));
    };
};

export const updateUser = (newData, oldData, user) => dispatch => {
    dispatch(requestUpdateUser());
    try {
        if (!newData.email) {
            throw ({ error: "email not specified" });
        }
        let ref = db.collection("users").doc(oldData.email);
        let doc = ref.update(newData)
        dispatch(recieveUpdateUser(doc))
        const info = {
            data: {
                from: oldData,
                to: newData
                }
        }
        if(user.details.email) logAction(user.details, 'usermanagement.update', `Successfully updated user` , info)

    } catch (error) {
        logAction(user.details, 'usermanagement.update', 'Failed to update user')
        dispatch(updateUserError(error));
    };
};

export const getUsers = () => dispatch => {
    dispatch(requestGET());
    try {
        let ref = db.collection('users')
        let doc = ref.get()
            .then(snapshot => {
                let users = []
                snapshot.forEach(doc => {
                    users.push(doc.data())
                });
                dispatch(recieveGetUser(users))
            })
            .catch(err => {
                dispatch(GetUserError(err));
            });
    } catch (error) {
        dispatch(GetUserError(error));
    };
};