import React, { Component } from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import styles from './styles.module.css'
import ListSubheader from '@material-ui/core/ListSubheader';

import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';
import FilterListIcon from '@material-ui/icons/FilterList';
import Chip from '@material-ui/core/Chip';

import Autocomplete from '@material-ui/lab/Autocomplete';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';

import InputBase from '@material-ui/core/InputBase';
import MenuIcon from '@material-ui/icons/Menu';
import ClearIcon from '@material-ui/icons/Clear';
import SearchIcon from '@material-ui/icons/Search';
import InputAdornment from "@material-ui/core/InputAdornment";
import CircularProgress from '@material-ui/core/CircularProgress';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import Avatar from '@material-ui/core/Avatar';
import Menu from '@material-ui/core/Menu';
import AdminActions from '../AdminActions';
import Fade from '@material-ui/core/Fade';
import { Container, Typography } from '@material-ui/core';
import LayerList from '../LayerList';
import Filter from '../Filter'; 
import GoogleMapAutocomplete from '../GoogleAutocomplete';
import Switch from '@material-ui/core/Switch';

import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
 

const icon = < CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = < CheckBoxIcon fontSize="small" />;

class SidePanel extends Component {

    constructor(props) {
        super(props);
        this.state = { 
            addressValue: '',
            addressClearable: false,
            filter: {
                service: '',
                provider: '',
                status: ''
            },
            isMenuOpen: false,
            displayFilter: true
        }
    }

    componentDidMount = () => {
        this.props.provideAutocompleteWidget(document.getElementById('pac-input'));
    }
 
    handleToggle = (value) => {
        this.props.handleToggle(value)
    }
 
    onServiceChange = (event, value) => {
        let filter = this.state.filter
        console.log(value)
        filter.service = value ? value.name : this.props.defaultService;
        // this.setState({ filter: filter, selectedService: value });
        this.setState({ filter: filter });
        this.props.setSelectedService(value ? value : this.props.defaultService)
        this.onFilterClicked();
    }

    onProviderChange = (event, value) => {
        let filter = this.state.filter
        if (value && Array.isArray(value)) {
            filter.provider = '';
            value.forEach((v, i) => {
                filter.provider += ',' + v.name
            });
        }
        this.setState({ filter: filter });
        this.onFilterClicked();
    }

    onStatusChange = (event, value) => {
        let filter = this.state.filter
        console.log(value)
        filter.status = value ? value.name : '';
        this.setState({ filter: filter });
        this.onFilterClicked();
    }

    onFilterClicked = (event) => {
        this.props.onFilterClicked(this.state.filter)
    }

    onClearClicked = (event) => {
        this.setState({
            filter: {
                service: this.props.defaultServices,
                provider: '',
                status: ''
            }
        });
        this.props.onClearClicked()
    }

    handleExpandClick = (item) => {
        this.props.handleLayerExpand(item)
    }

    handleAddressChange = (address) => {
        let addressClearable = this.state.addressClearable
        addressClearable = address ? true : false;
        console.log('value: ' + address + ' = ' + addressClearable);
        if (this.props.handleAddressChange) this.props.handleAddressChange(address);
        this.setState({ addressClearable: addressClearable });
    }

    // clearAddressField = (event) => {
    //     let addressClearable = this.state.addressClearable
    //     addressClearable = false;
    //     this.setState({ addressClearable: addressClearable, addressValue: '' });
    //     if (this.props.handleAddressChange) this.props.handleAddressChange('');
    // }

    handleProfileMenuOpen = (event) => {
        this.setState({ isMenuOpen: !this.state.isMenuOpen, anchorEl: event.currentTarget })
    }

    handleMenuClose = (event) => {
        this.setState({ isMenuOpen: false })
    }

    handleLogout = (event) => {
        this.handleMenuClose(event);
        this.props.handleLogout();
    }

    handleManageUsers = (event) => {
        this.handleMenuClose(event);
        this.props.handleManageUsers();
    }
    handleFilterToggle = (event) => {
        this.setState({ displayFilter: !this.state.displayFilter })
    }
    handleLocationSearchSwitch = (event) => {
        this.props.handleLocationSearchSwitch();
    }

    render() {
        return (
            <Paper className={styles.root} >
                <Container disableGutters className={styles.search} >
                    <GoogleMapAutocomplete
                        clearAddressField={this.clearAddressField}
                        handleAddressChange={this.handleAddressChange}
                        className={styles.searchInput}
                        user={this.props.user}
                        handleFilterToggle={this.handleFilterToggle}
                    />
                </Container >
                <Divider />
                <Collapse in={this.state.displayFilter}>
                    <Container disableGutters className={styles.settings} >  
                        <FormControl component="fieldset" style={{ width: 'calc(100% - 4px)' }}>
                            <FormGroup aria-label="position" row> 
                                <FormControlLabel 
                                    style={{ justifyContent: 'space-between', width: '100%', marginLeft: '0'}}
                                    control={<Switch color="primary" />}
                                    label={<ListSubheader >Display coverage at location </ListSubheader>}
                                    size="small"
                                    labelPlacement="start"
                                    checked={this.props.enableLocationPip}
                                    onChange={this.handleLocationSearchSwitch}
                                /> 
                            </FormGroup>
                        </FormControl>
                    </Container>
                    <Divider />
                    <Container disableGutters className={styles.layers} >
                        <Filter
                            data={this.props.data}
                            selectedService={this.props.selectedService} 
                            onStatusChange={this.onStatusChange}
                            onProviderChange={this.onProviderChange}
                            onServiceChange={this.onServiceChange}
                        />
                    </Container >
                </Collapse>
                <Divider />
                <Container disableGutters className={styles.layers} >
                    <LayerList
                        layers={this.props.layers}
                        layerControlTitle={this.props.layerControlTitle}
                        handleToggle={this.handleToggle}
                        handleLayerExpand={this.handleExpandClick}
                    />
                </Container >
            </Paper>
        );
    }
}
export default SidePanel;
